.Item{
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    border-bottom: 1px solid #F6F6F6;
    padding-bottom: 8px;
}
.Item div {

    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}
.Num{
    width: 12%;
    color: #808080;
}
.Num span{
    font-size: 10px;
}
.Sum{
    width: 24%;
}
.Num span{
    margin-top: 0;
}
.Debit{
    width: 30%;
    color: #FF4040;
}
.Debit.Active{
    width: 30%;
    color: #0ECD21;
}
.Credit{
    width: 24%;
}
.Date{
    width: 20%;
    color: #808080;
}