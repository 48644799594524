.Checkbox {
    position: absolute;
    right: 11px;
}
.Checkbox input {
    position: relative;
    height: 16px;
    width: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    top:3px;
}
.Checkbox input::before {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #808080;
    border-radius: 6px;
}

.Checkbox input:checked::before {
    border-color: #2D8546;
}

.Checkbox input:checked::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    background: url("../../../../../assets/img/checkbox-green.svg");
    transform: translate(-50%, -50%);
    visibility: visible;
}
