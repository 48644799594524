.controlSection {
  border-bottom: 1px solid #F6F6F6;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.controlSection div:first-child {
  width: 100%;
}

/* .users {
  height: 600px;
  overflow-y: auto;
} */

.users::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 8px;
}

.leftBlockOpen {
  max-height: calc(100vh - 233px);
  overflow-y: auto;
}

.leftBlockOpen::-webkit-scrollbar {
  /* width: 10px; */
}

.leftBlockOpen::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.leftBlockClose {
  max-height: calc(100vh - 295px);
  overflow-y: auto;
}


.leftBlockClose::-webkit-scrollbar {
  /* width: 10px; */
}

.leftBlockClose::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}