.title {
  color: #202020;
  font-family: 'Inter';
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 24px;
}

.wrapperContainer {
  width: 100%;
  margin-top: 40px;
}

.moreButton {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.moreButton button {
  border-radius: 16px;
  background: #F6F6F6;
  width: 320px;
  height: 48px;
  border: none;
  color: #808080;
  text-align: center;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
}