.TitleBlock {
  margin-bottom: 30px;

}

.TitleBlock h2 {
  color: #000;
  font-family: 'Inter';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}