.container {
    width: 320px;
}

.content {
    display: flex;
    width: 320px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 16px;
    background: #F6F6F6;
}

.link {
    width: 90%;
}

.content div {
    width: 100%;
    border-radius: 8px;
    padding: 4px 0px;
    padding-left: 8px;
    cursor: pointer;
    box-sizing: border-box;
}

.accordion {
    max-width: 400px;
    margin: 20px auto;
}

.accordion_item {
    margin-bottom: 5px;
}

.accordion_item_title {
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    padding-right: 6px !important;
    display: flex;
    align-items: center;
}

.subTitle {
    padding-left: 0px;
}

.accordion_item_content {
    display: none;
    padding: 10px;
}

.accordion_item_title.active,
.accordion_item_title:hover {
    transition: background-color 0.2s ease-in;
    background-color: #2D8546;
}

.accordion_item_title.active span,
.accordion_item_title:hover span {
    color: #fff;
}

.accordion_item_content.active {
    display: block;
}

.accordion_item_title.active {
    fill: red
}

.accordion_item_title_text {
    color: #808080;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    word-break: break-word;
}

.accordion_item_title_text_first {
    width: 200px;
    color: #202020;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

.arrowIcon {
    fill: #7D40FF;
    transition: all 0.3s ease-in-out;
}

.dotIcon {
    fill: #7D40FF;
    transition: all 0.3s ease-in-out;
    margin-right: 4px;
}

.accordion_sub_item {
    padding-left: 0px !important;
}

.dot {
    width: 4px !important;
    height: 4px !important;
    margin-right: 4px;
    background-color: #D9D9D9;
    border-radius: 50px;
}

.deg0 {
    transform: rotate(0deg) !important;
}

.deg180 {
    transform: rotate(180deg) !important;
}