.wrapper {
    display: flex;
    flex-direction: column;
    padding: 6px 12px;
    cursor: pointer;
}

.wrapper:hover {
    background-color: #f5f5f5;
}

.title {
    font-size: 14px;
    font-weight: 400;
}

.subTitle {
    font-size: 12px;
    color: #838383;
    margin-top: 4px;
}

.errorMessage {
    font-size: 12px;
    line-height: 14px;
    color: #FF0000;
    margin-top: 4px;
}

.empty {
    font-size: 12px;
    color: #838383;
}