.ListHeader {
    display: flex;
    justify-content: space-between;
}

.ListHeader div {
    color: #808080;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}
.Num{
    width: 8%;
}
.Sum{
    width: 18%;
}
.Debt{
    width: 26%;
}
.Comment{
    width: 30%;
}
.Credit{
    width: 16%;
}
.Date{
    width: 20%;
}
.NoData{
    margin-top: 30px;
    text-align: center;
}
.NoData h1{
    font-size: 14px;
}
.ButtonBlock{
    margin-bottom: 25px;
}
.FullCreditInfo {
    border-top: 1px solid #F6F6F6;
    font-size: 14px;
    line-height: 18px;
    font-size: 12px;
}
.FullCreditValue {

}
.FullCreditInfo label>span {
    color: #808080;
}
.IsCredit {
    color: red;
    font-weight: 600;
}
.IsDebit {
    color: green;
    font-weight: 600;
}
