.wrapper {
    display: flex;
    margin-top: 20px;
    gap: 10px;
}

.searchIcon {
    margin-right: 12px;
}

.button {
    align-self: flex-end;
}

.inputWrapper {
    width: 300px;
}
