.editBtn {
    border: none;
    background-color: transparent;
    width: 27px;
    height: 27px;
    margin-right: 14px;
    cursor: pointer;
}

.editIcon {
    width: 100%;
    height: auto;
}

.number {
    font-weight: 600;
}

.secondary {
    color: #707070;
}
