.TitleBlock {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    padding: 0 0 24px 0 !important;
    border-bottom: 1px dashed #E6E6E6;
}
.TitleBlock > h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
}
.CreateProjectContent {
    position: relative;
    font-size: 14px;
    line-height: 16px;
    color: #202020;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    padding: 50px 90px;
}
.CreateProjectContent h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    margin-top: 24px;
    margin-bottom: 12px;
}
.CreateEntityBlock:not(:last-child) {
    padding-bottom: 24px;
    border-bottom: 1px dashed #E6E6E6;
}
.Label,
.CreateProjectContent :global(.label) {
    display: block;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;
    color: #202020;
}
.CreateProjectContent :global(.label) {
    margin-top: -10px;
    margin-bottom: 20px;
}
.Input {
    border: 1px solid #383838;
    border-radius: 8px;
    height: 38px;
    width: 100%;
    padding: 2px 12px;
}
.Input:hover {
    border: 1px solid hsl(0, 0%, 70%);
}
.Input:focus, .Input:active {
    box-shadow: none;
    outline: none;
}
.Button {
    /*width: 100%;*/
}
.CreateProjectContent :global(.selPref__control) {

}
.CreateProjectContent :global(.selPref__menu) {
    width: calc(100% - 25px)!important;
}
.CreateProjectContent :global(.selPref__menu-list) {
    max-height: 300px !important;
    overflow-y: scroll;
}
.OffersSelect :global(.uiSelect__menu-list::-webkit-scrollbar) {
    display: none;
}
.OffersSelect :global(.uiSelect__menu-list) {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.OffersSelect :global(.uiSelect__control:hover) {
    border: 1px solid hsl(0, 0%, 70%);
}
.CreateProjectContent :global(.selPref__control),
.CreateProjectContent :global(.selPref__control:focus),
.CreateProjectContent :global(.selPref__control:active) {
    box-shadow: none !important;
    outline: none !important;
}
.OffersSelect :global(.uiSelect__option) {
    padding-top: 7px;
    padding-bottom: 7px;
}
.OffersSelect :global(.uiSelect__option input::before) {
    width: 18px;
    height: 18px;
    border-radius: 3px;
}
.OffersSelect :global(.uiSelect__option input::after) {
    width: 14px;
    height: 14px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.SelectedOffer {
    font-weight: 500;
    font-size: 10px;
    display: flex;
    align-items: center;
}
.DeleteOfferIcon {
    /*margin-left: 10px;*/
    cursor: pointer;
    color: red;
}
