.container {
    margin-top: 32px;
}

.text {
    color: #999;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.sort {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #F1F1F1;
}

.sort img {
    margin-left: 13px;
    cursor: pointer;
}

.sort:hover {
    fill: #61B073;
}

.items {
    margin-top: 20px;
}