.wrapper {
    position: relative;
    width: 100%;
    height: fit-content;
}

.icon {
    z-index: 10;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 26px;
    transform: translate(-50%, -54%);
}

.msgStyles {
    font-size: 14px !important;
    padding: 8px 12px;
    color: #202020;
    text-align: center;
    cursor: default;
}

.InputBlock {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}