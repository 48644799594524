.searchSpinner img {
  position: absolute;
  top: 35%;
  right: 15px;
  animation: matrixSpinner infinite linear 2s;
  transform: translate(-50%, -50%);
}

@keyframes matrixSpinner {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}