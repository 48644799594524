.sidebar {
    width: 277px;
}

.tab {
    color: #8A8A8A;
    padding: 17px 0 12px 0;
    cursor: pointer;
}

.tab:hover {
    color: #000000;
}

.active {
    color: #000000;
    border-right: 6px solid #2D8546;
}