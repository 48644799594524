.dragnDrop .column{
    margin: 8px;
    border-right:1px solid #F6F6F6;
    border-radius: 10px;

}
.dragnDrop h3{

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
}
.rulesList{
    margin-top: 28px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.ruleItem {
    padding: 8px;
    text-align: center;
    margin-bottom: 8px;
    background: #ECECEC;
    border-radius: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: inline;
}
