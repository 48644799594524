.container {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
}

.title {
    color: #202020;
    font-family: 'Inter';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 15px;
}

.link {
    color: #2D8546;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 8px;
    cursor: pointer;
}

.link:hover {
    font-weight: 500;
}