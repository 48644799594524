.DashboardContainer{
    padding: 32px;
    border: 1px solid #ECECEC;
    border-radius: 24px;
    min-height: 400px;
}
.TitleBlock{
   display: flex;
    justify-content: space-between;
}
.TitleBlock p{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #2D8546;
    margin: 0;
}
.TitleBlock .Date{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color:rgba(32, 32, 32, 1);
    margin: 0;
}
.TitleBlock h3{
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #202020;
    margin-top: 16px;
}
.Link{
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #2D8546;
    cursor: pointer;
    text-align: right;
    margin-right: 0;
    margin-left: auto;
    justify-content: flex-end;
    margin-top: 16px;
    display: flex;

}
.Link svg{
     margin-left: 8px;
     position: relative;
     top: 2px;
}
