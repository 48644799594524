.input__error {
  font-size: 10px;
  line-height: 16px;
  color: #FF4040;
  position: absolute;
  bottom: 11px;
  left: 15px;
}

@media(max-width: 500px) {
  .input__error {
    font-size: 10px;
    line-height: 12px;
    bottom: -11px;
  }
}