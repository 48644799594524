.OfferCardContent {
    position: relative;
    font-size: 14px;
    line-height: 16px;
    color: #202020;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    padding: 24px;
}

.BackLink {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
}

.BackLink:hover {
    font-weight: 700;
}

.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.Title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin: 0;
    margin-bottom: 1rem;
}

.Body {
    /* margin-top: 30px; */
    box-sizing: border-box;
}