.wrapperContainer {
    /* padding-top: 15px; */
    padding-bottom: 30px;
    box-sizing: border-box;
}

.container {
    display: flex;
    padding: 24px;
    padding-bottom: 8px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 24px;
    border: 1px solid #ECECEC;
    min-height: 300px;
    box-sizing: border-box;
}

.container:hover {
    border: 1px solid #2D8546;
}

.image {
    width: 56px;
    height: 56px;
    object-fit: cover;
    overflow: hidden;
    border-radius: 8px;
    display: block;
    pointer-events: none !important;
}

.title {
    margin-top: 24px;
    color: #202020;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 8px;
    word-break: break-word;
    pointer-events: none !important;
}

.description {
    color: #808080;
    /* font-family: 'Inter'; */
    /* font-size: 14px; */
    /* font-style: normal; */
    /* font-weight: 400; */
    /* line-height: 18px; */
    margin-bottom: 24px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    pointer-events: none !important;
}

.description p span {
    background-color: transparent !important;
}

.description * {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.links {
    display: flex;
    flex-direction: column;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    pointer-events: none !important;
}

.link {
    color: #2D8546;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    pointer-events: none !important;
    margin-bottom: 8px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}