.ReportTableRow {
    width: fit-content;
    border-bottom: 1px solid #808080;
}
.ReportTableRow * {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
}
