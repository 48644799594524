.Item{
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    border-bottom: 1px solid #F6F6F6;
    padding-bottom: 8px;
}
.Item div {

    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}
.Num{
    width: 8%;
    color: #808080;
}
.Sum{
    width: 24%;
}
.Debt{
    width: 26%;
}
.Comment{
    width: 30%;
}
.Credit{
    width: 16%;
}
.Date{
    width: 20%;
    color: #808080;
}