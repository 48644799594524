.wrapperContainer {
    display: flex;
}

.container {
    padding: 0px;
    position: relative;
}

.wrapper {
    position: relative;
}