.container {}

.imgAndTitleBlock {
    padding-right: 0px;
}

.imgAndTitle {
    display: flex;
    align-items: center;
}

.imgAndTitle img {
    width: 56px;
    height: 56px;
    margin-right: 24px;
    border-radius: 10px;
}

.imgAndTitle h2 {
    color: #202020;
    font-family: 'Inter';
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}



.description {
    display: flex;
    margin-top: 24px;
    color: #222;
}

.description p span {
    background-color: transparent !important;
}

.editBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.editBtn img {
    cursor: pointer;
}

.removeIcon {
    width: 22px;
    height: 22px;
    margin-left: 10px;
}