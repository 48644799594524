.Num {
    display: flex;
    align-items: center;
    padding-left: 15px;
    width: 5%;
}

.Partner {
    display: flex;
    align-items: center;
    width: 14%;
}

.Manager {
    display: flex;
    align-items: center;
    width: 13%;
    padding-left: 15px;
}

.Recipient {
    display: flex;
    align-items: center;
    width: 8%;
}

.Sum {
    display: flex;
    align-items: center;
    width: 8%;
}

.ConvertSum {
    display: flex;
    align-items: center;
    width: 7%;
    padding-left: 5px;
}

.Status {
    display: flex;
    align-items: center;
    width: 10%;
    padding-left: 40px;
}

.PaymentType {
    display: flex;
    align-items: center;
    width: 10%;
    padding-left: 20px;
}

.Type {
    display: flex;
    align-items: center;
    width: 6%;
    padding-left: 30px;
}

.Tax {
    display: flex;
    align-items: center;
    width: 7%;
    padding-left: 15px;
}

.Status .Alert {
    margin-top: 4px;
    margin-bottom: 8px;
}

.Date {
    display: flex;
    align-items: center;
    width: 6%;
    padding-left: 15px;
}

.PaySystem {
    width: 8%;
}

.TaxCountContainer .Sum,
.TaxCount {
    display: flex;
    flex-direction: column;
}

.TaxCountContainer .TaxCount {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
    color: #202020;
    font-size: 12px;
    padding-left: 115px;
}

.SumBlock {
    display: flex;
    flex-direction: column;
    padding-left: 330px;
    align-items: flex-start;
    line-height: 16px;
}

.PaySystem span {
    background: #F6F6F6;
    border-radius: 16px;
    padding: 8px 16px;
    display: inline-block;
    margin-top: 0;

}

.Date:hover,
.Sum:hover,
.Status:hover {
    /*color: #2D8546*/
}

.ListHeader {
    padding: 15px;
    border-bottom: 1px solid #ECECEC;
    background: #2D8546;
    border-radius: 8px;
    position: relative;
}

.ListHeader>div {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    margin: 0;
    display: flex;
}

.ListHeader:first-child>div {
    /* font-weight: bold; */
    cursor: pointer;
}

.TaxCountContainer {
    width: 100%;
    min-height: 32px;
    text-align: right;
    background: #E6E6E6;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    border-bottom: none;
    display: flex;
    align-items: flex-start;
    margin-top: 15px;
}

.Billings_Content {
    margin-top: 40px;
    min-height: 600px;
}

.Active {
    display: flex;
    align-items: center;
}

.ActiveDouble {
    display: flex;
    align-items: flex-start;
    height: 14px;
}

.Active .Down,
.Active .Up {
    background: url("../../../../assets/img/icon-dashed-piramid.svg") no-repeat center center;
    background-size: contain;
    width: 20px;
    height: 14px;
}

.ActiveDouble .Down,
.ActiveDouble .Up {
    background: url("../../../../assets/img/icon-dashed-piramid.svg") no-repeat center center;
    background-size: contain;
    width: 24px;
    height: 14px;
}

.Active .Up {
    transform: rotate(180deg);
}

.ActiveDouble .Up {
    transform: rotate(180deg);
}

.Icon {}



.TaxCountItem {
    line-height: 16px;
}

.TaxCountContainer .Sum span {
    margin-top: 3px;
    font-size: 12px;
}

.ListHeaderTitle {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    color: #808080;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.BillingSumRevenueTitle {
    color: #808080;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-top: 10px;
    padding-bottom: 6px;
}

.TaxCountTitle {
    display: flex;
    color: #808080;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-top: 2px;
    padding-bottom: 6px;
}

/*@media screen and (max-width: 1400px) {*/
/*    .Partner {*/
/*        width: 22%;*/
/*    }*/

/*    .Date {*/
/*        width: 12%;*/
/*        display: flex;*/
/*    }*/

/*    .Billings_Content {*/
/*        margin-top: 40px;*/
/*        min-height: 450px;*/
/*    }*/
/*}*/
