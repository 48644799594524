.ReportTableRow {
    width: fit-content;
    border-bottom: 1px solid #808080;
}
.ReportTableRow * {
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
}
.TableCell {
    padding: 10px;
}
.FirstColumn {
    display: flex;
    align-items: center;
}
