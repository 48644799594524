.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: 300px;
}

.logo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.logo img {
  width: 162px;
}

.password_input {
  position: relative;
  margin: 0px;
}

.password_input input {
  margin: 0px;
  padding: 10px;
  margin-top: 18px;
  padding-right: 30px;
}

.login_btn {
  padding: 0px;
  background-color: #383838;
  width: 100%;
  height: 36px;
  color: #FFF;
  font-family: 'Bebas';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 92%;
  border-radius: 6px;
  margin-bottom: 20px;
  margin-top: 22px;
}

.eye_icon {
  position: absolute;
  right: 10px;
  top: 31px;
  cursor: pointer;
}

.error_text {
  font-size: 10px;
  color: #FF4040;
}

.is_send_text {
  color: #000;
  text-align: center;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin-top: 52px;
}