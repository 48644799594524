.Item {
    width: 40px;
    height: 40px;
    background: #F6F6F6;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
}

.Item:hover{
    background: #2D8546;
    color: #FFFFFF;
}

.Active{
    background: #2D8546;
    color: #FFFFFF;
}
.Btn{
    width: auto;
    height: auto;
    padding: 8px 2px;
    color: #2D8546;
    display: inline-block;
    background: none;
    font-weight: 700;
    font-size: 120%;
}
