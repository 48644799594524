.ModalModule {
    border-radius: 30px;
    background: #FFF;
    padding: 66px 176px;
}

.Header {
    display: flex;
    justify-content: center;
    /* margin-bottom: 50px; */
    flex-direction: column;
}

.title {
    color: #000;
    font-family: 'Inter';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
}

.titleName {
    color: #2D8546;
    font-family: 'Inter';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    word-break: break-word;
    text-align: center;
}

.infoText {
    text-align: center;
    color: #000;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 24px;
}

.infoTextSecond {
    text-align: center;
    color: #000;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 50px 0px;
}

.btns {
    display: flex;
    justify-content: center;
    /* margin-top: 50px; */
}

.createArticle {
    padding: 0px 22px;
    height: 36px;
    color: #FFF;
    font-family: 'Bebas';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 92%;
    border-radius: 6px;
    margin: 0px 25px;
    background-color: #383838 !important;
}

.createArticle:hover {
    background-color: #171717 !important;
}

.viewBtn {
    padding: 0px;
    padding: 0px 22px;
    height: 36px;
    color: #333;
    font-family: 'Bebas';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 92%;
    border-radius: 6px;
    border: 1px solid #333;
    margin: 0px 25px;
    background-color: transparent !important;
}

.viewBtn:hover {
    background-color: #FAFAFA !important;
}