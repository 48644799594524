/* .ReportProjectRow {
    border-top: 1px solid #CCCCCC;
} */
.ProjectHeader {
    display: flex;
    justify-content: space-between;
    padding-left: 2px;
}
.SwitchBlock {
    display: flex;
    width: calc(100% - 80px);
    /* width: 340px; */
    justify-content: space-between;
}
.TableCell {
    padding: 10px;
}
.IncomeNames {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-left: 5px;
    width: 250px;
    height: 35px;
    justify-content: center;
    overflow-wrap: anywhere;
}
.ExpensesNames {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 250px;
    height: 35px;
    justify-content: center;
    overflow-wrap: anywhere;
}
.TableOffersCell {
    /* margin-left: 5px; */
    width: 0;
    display: flex;
}
.TableOffers {
    padding-left: 18px;
    width: 150px;
    height: 35px;
    display: flex;
    align-items: center;
}
.TableOffersDisplay {
    padding-left: 20px;
    width: 250px;
    height: 35px;
    display: flex;
    align-items: center;
}
.TableOffersBeforeSign {
    width: 300px;
}
.CurrencyOffers {
    /* width: 100%; */
    width: 100%;
    display: flex;
    flex-direction: column;
}
.TableSignMinus {
    font-size: 100% !important;
    cursor: pointer;
    margin-right: -2px;
}
.TableSignPlus {
    font-size: 100% !important;
    cursor: pointer;
    position: absolute;
    margin-left: -10px;
}
.TableSignMinusOffers  {
    font-size: 170% !important;
    cursor: pointer; 
    position: absolute;
    margin-left: -14px;
}
.TableSignMinusIncome  {
    font-size: 170% !important;
    cursor: pointer; 
    position: absolute;
    margin-left: -14px;
}
.TableSignMinusXPOffers  {
    font-size: 170% !important;
    cursor: pointer; 
    position: absolute;
    padding-left: 232px;
}
.TableSignPlusOffers  {
    font-size: 170% !important;
    cursor: pointer;
    padding-left: 230px;
    position: absolute;
}
.TableSignPlusExpenses  {
    font-size: 170% !important;
    cursor: pointer;
    /* padding-left: 234px; */
    margin-left: -16px;
    position: absolute;
}
.TableSignPlusIncome  {
    font-size: 170% !important;
    cursor: pointer;
    /* padding-left: 239px; */
    margin-left: -16px;
    position: absolute;
}
.Offers {
    display: flex;
    /* width: 67.55%; */
    width: 100%;
}
.bbGrey {
    border-bottom: 1px solid #ECECEC;
}
