.title {
    color: #000;
    font-family: 'Inter';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 25px;
}

.ConversionsCSV {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
}

.ConversionsCSV h4 {
    color: #808080;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
}

.ConversionsCSV h5 {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

.RadioBlock {
    display: flex;
}

.RadioBlock>div:first-child {
    margin-left: 0;
}

.ImportNotice {
    background: #E9FFEB;
    border-radius: 16px;
    padding: 15px 25px;
}

.PreviewTable th,
.PreviewTable td {
    padding: 5px;
}

.UploadFileName {
    margin-left: 15px;
    color: #C4C4C4;
}

.UploadFileName.Uploaded {
    color: #000000;
}

.ResultRow h4 {
    padding-left: 15px;
}

.ButtonBlock {
    display: flex;
}

.ButtonBlock button:first-child {
    margin-right: 25px;
}

.TableImg {
    width: 100%;
    height: auto;
}

.ResultMessage {
    margin-top: 25px;
    color: blue;
}

.Legend {
    font-size: 14px;
    line-height: 22px;
    margin-right: 34px;
}

.Legend span {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #2D8546;
}