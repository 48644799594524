.main404 {
    position: relative;
    height: 100%;
    text-align: center;
}
.pageHeader {
    width: 100%;
    padding-top: 15px;
    font-size: 22px;
    line-height: 28px;
}

.img404 {
    position: absolute;
    bottom: 0;
    max-width: calc(100% + 50px);
    height: auto;
    right: -50px;
    z-index: 10;
}

@media (min-width: 360px) {
    .pageHeader {
        padding-top: 30px;
        font-size: 30px;
        line-height: 41px;
    }
}

@media (min-width: 768px) {
    .main404 {
        overflow-x: hidden;
        padding: 30px;
    }
    .img404 {
        right: -150px;
        width: 1280px;
    }
}

@media (min-width: 992px) {
    .pageHeader {
        padding: 50px 0 35px;
        font-weight: 600;
        font-size: 48px;
        line-height: 65px;
    }
    .img404 {
        right: -17%;
        max-width: 80%;
    }
}
