.searchBg {
    width: 100%;
    background: rgba(32, 32, 32, 0.6);
    position: absolute;
    top: 0px;
    z-index: -10;
    opacity: 0;
    height: 100vh;
}

.searchBgPassive {
    animation: fadeInDown;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.searchBgActive {
    animation: fadeInUp;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    overflow: hidden;
}


@keyframes fadeInUp {
    0% {
        z-index: 2;
        opacity: 0;
    }

    100% {
        opacity: 1;
        z-index: 2;
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 1;
        z-index: 2;
    }

    99% {
        z-index: 2;
        opacity: 0;
    }

    100% {
        z-index: -10;
    }


}