.wrapper {
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 14px;
    font-weight: 400;
}

.fileUpload {
    display: flex;
    align-items: center;
    margin: 8px 0;
    height: 20px;
    flex: 1;
}

.fileUploadButton {
    display: flex;
}

.fileUploadButton label {
    font-family: 'Bebas', sans-serif;
    font-size: 16px;
    margin-left: 8px;
}

.description {
    font-size: 12px;
    color: #838383;
}

.input {
    display: none;
}

.fileNameWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}

.fileName {
    display: flex;
    background-color: #EEEEEE;
    padding: 12px 14px;
    border-radius: 8px;
    width: 278px;
}

.fileName span {
    margin-left: 10px;
    font-size: 12px;
    color: #838383;
}

.deleteBtn {
    color: #B23333;
    font-size: 12px;
    background: none;
    border: none;
    cursor: pointer;
}