.add{
    background: #F6F6F6;
    padding: 0;
    width: 40px;
    height: 40px;
    border-radius: 16px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tab{
    color: #202020;
    background: none;
}
.tab.active{
    background: #F6F6F6;
}
.delete{
    background: none;
    padding-top: 5px;
    color: #FF4040;
    margin-right: 16px;
}

.login{
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    padding: 16px 32px;
    border-radius: 40px;

}
.Line{
    background: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding: 12px 16px;
    color: #7D40FF;
    border: 1px solid #7D40FF;
    border-radius: 16px;

}
.Line span{
    background: red;
    width: 16px;
    height: 16px;
    margin-right: 6px;
}