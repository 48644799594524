.Btn {
    padding: 7px 16px 5px;
    height: 38px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-family: 'Bebas', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
}
.Btn:active {
    transform: translateY(2px);
}
.Btn:disabled {
    cursor: default;
    transform: unset;
}
.Primary {
    color: #FFFFFF;
    background: #383838;
}
.Primary:hover {
    background: #171717;
}
.Primary:disabled {
    background: #CCCCCC;
}
.Secondary {
    color: #333333;
    background: #FFFFFF;
    border: 1px solid #333333;
}
.Secondary:disabled {
    color: #CCCCCC;
    border-color: #CCCCCC;
}
.Danger {
    color: #B23333;
    background: #FFFFFF;
    border: 2px solid #B23333;
}
.Danger:hover {
    background: #FFEFEF;
}
.Warning {
    color: orange;
    background: #FFFFFF;
    border: 2px solid orange;
}
.Approve {
    color: #2D8546;
    background: #FFFFFF;
    border: 2px solid #2D8546;
}
.Approve:hover {
    background: #C4FECB;
}
