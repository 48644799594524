.ProjectGroupRow {
    padding: 12px 0;
    border-bottom: 1px solid #F1F1F1;
}
.ProjectGroupRow:first-child {
    border-top: 1px solid #F1F1F1;;
}
.ProjectGroupRow > * {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
}
.IconCollapse {
    width: 5px;
    margin-right: 15px;
    cursor: pointer;
}
.ProjectGroupIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 31px;
    width: 30px;
    border-radius: 4px;
    background-color: #AFF1B7;
    margin-right: 15px;
}
.ProjectsList {}

.Button {
    display: flex;
    align-items: center;
    border: none;
    color: #999999;
    background-color: transparent;
    height: 34px;
    font-family: unset;
    font-size: 13px;
    line-height: 92%;
    font-weight: 400;
}
.Button.Delete svg > path {
    stroke: #999999;
}
.Button.Edit:hover svg > path {
    fill: #2D8546;
}
.Button.Delete:hover svg > path {
    stroke: #B23333;
}
.Input {
    border: 1px solid #383838;
    border-radius: 8px;
    height: 38px;
    width: 100%;
    padding: 2px 12px;
}
.Input:hover {
    border: 1px solid hsl(0, 0%, 70%);
}
.Input:focus, .Input:active {
    box-shadow: none;
    outline: none;
}
