.container {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
}

.slash {
    margin-left: 6px;
    margin-right: 6px;
}

.link {
    height: fit-content;
    display: flex;
    color: #222;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.link:hover {
    color: #2D8546;
}