.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.label {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;
    display: block;
}

.textarea {
    font-size: 14px;
    line-height: 16px;
    padding: 8px 12px;
    border-radius: 6px;
    width: 100%;
    resize: none;
    border: 1px solid #333333;
    background-color: #FFFFFF;
    height: 120px;
}

.error {
    border: 1px solid #FF0000;
}

.errorMessage {
    font-size: 12px;
    line-height: 14px;
    color: #FF0000;
    margin-top: 4px;
}
