.wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
}

.loader{
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
}

.loader .loading{
    background: #2D8546;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    margin: 0 10px;
    animation: load 0.7s ease infinite;
}

.loader .loading.one{
    animation-delay: 0.3s;
}

.loader .loading.two{
    animation-delay: 0.4s;
}

.loader .loading.three{
    animation-delay: 0.5s;
}

@keyframes load{
    0%{
        width: 30px;
        height: 30px;
    }
    50%{
        width: 20px;
        height: 20px;

    }
}