.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.label {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;
}
