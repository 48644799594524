.navigation {
    display: flex;
    height: 80px;
    justify-content: center;
    width: 100%;
    margin-top: 0;
    margin-right: 24px;
    position: fixed;
    background-color: #fff;
    z-index: 10;
    box-shadow: 0px 9px 12px 0px rgba(0, 0, 0, 0.09);
}

.logoBlock {
    font-size: 24px;
    font-weight: 700;
    /* margin-right: 60px; */

}

.logoBlock img {
    width: 145px;
}

.container {
    box-sizing: border-box;
}

.navigation>.container {
    display: flex;
    justify-content: space-between;
    max-width: none;
    width: 100%;
    align-items: center;
    padding: 0 116px;
}

.navigation>.container .container {
    display: flex;
    justify-content: center;
}

.navigationBlock {
    display: flex;
    align-items: center;
}

.menu {
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.auth-block a {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #a6a6a6;
}

.navItem {
    margin-right: 14px;
    margin-left: 14px;
    height: 30px;
    display: flex;
    align-items: center;
}


.navItem a:hover {
    color: #202020;
}

.navItem.innerBlock {
    height: auto;
    position: relative;
}

.navItemLabel {
    display: flex;
    align-items: center;
}

.navItemLabel span {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #a6a6a6;
    /* transition: ease-out 0.3s; */
    cursor: pointer;
    margin-right: 4px;
}

.navItem .navItemLabel span.active {
    color: #202020;
}

.navItem a,
.navItemLabel span {
    color: #a6a6a6;
    font-family: 'Bebas';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* transition: ease-out 0.3s; */
}

.InnerList {
    position: absolute;
    min-width: 325px;
    top: 100%;
    padding: 8px 16px;
    border-radius: 12px;
    background: #fff;
    left: 0px;
    display: none;
    z-index: 2;
    box-shadow: -1px 1px 11px -2px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 3px -2px rgba(0, 0, 0, 0.75);
}

.navItem:hover .InnerList {
    display: flex;
    flex-direction: column;
}

.navItem:hover span {
    color: #202020;
}

.navItem:hover svg {
    transform: rotate(180deg);
    fill: #202020;
}

.navItem svg {
    fill: #a6a6a6;
}

.InnerList li {
    margin: 4px 0px;
}

.InnerList li a {
    margin: 0;
}

.InnerList li:hover>a {
    color: #202020;
}

.menu .navItem a.active {
    color: #202020;
}

/* .navItem:hover>a {
  color: #202020;
} */

.navItem:hover svg {
    fill: #202020;
}

.navItem:hover .arrow svg {
    fill: #202020;
    transform: rotate(180deg);
    position: relative;
    top: 0;
}

.navItem.logout {
    height: 50px;
    z-index: 2;
}

.logout {
    display: flex;
    align-items: center;
    position: relative;
    height: 40px;

}

.logout a {
    flex-shrink: 0;
    line-height: 0;
}

.dropdown .submenu {
    position: absolute;
    top: 30px;
    width: 500px;
    opacity: 0;
    display: none;
}

.dropdown:hover .submenu {
    opacity: 1;
    display: block;
}

.dropdown:hover .submenu li:hover a {
    color: #202020;
}

.dropdown.logout .submenu {
    width: auto;
    position: absolute;
    top: 40px;
    min-width: calc(100% + 24px);
    float: right;
    right: 0;
}

.dropdown .submenu ul {
    background: #F6F6F6;
    border-radius: 16px;
    padding: 12px 16px;
    position: absolute;
    right: -12px;
    width: 100%;
    z-index: 2;
}

.dropdown .submenu li {
    padding: 4px 0;
}

.mail_text {
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #A7A7A7;
    width: max-content;
}

.logout_icon {
    margin-left: 10px;
    cursor: pointer;
}

@media (max-width: 1660px) {
    .navigation>.container {
        display: flex;
        justify-content: space-between;
        max-width: none;
        width: 100%;
        align-items: center;
        padding: 0 16px;
    }
}

@media (max-width: 1410px) {
    .menu {
        display: flex;
        justify-content: space-around;
        position: relative;
        width: 92%;
    }

    .navItem a {
        font-size: 16px;
    }

    .icon.arrow {
        left: 1px;
    }

    .navItem {
        margin-right: 8px;
        margin-left: 8px;
    }

    .navItemLabel span {
        font-size: 16px;
    }

    .InnerList {
        min-width: 290px;
    }

    .mail_text {
        font-size: 14px;
    }
}

@media (max-width: 1300px) {
    .menu {
        display: flex;
        justify-content: space-around;
        position: relative;
        width: 94% !important;
    }

    .navItem a {
        font-size: 16px;
    }

    .icon.arrow {
        left: 1px;
    }

    .navItem {
        margin-right: 2px;
        margin-left: 2px;
    }

    .navItemLabel span {
        font-size: 16px;
    }

    .InnerList {
        min-width: 290px;
    }

    .mail_text {
        font-size: 14px;
    }
}

@media (max-width: 1209px) {
    .menu {
        display: flex;
        justify-content: space-around;
        position: relative;
        width: 98% !important;
    }

    .navItem a {
        font-size: 16px;
    }

    .navItemLabel span {
        font-size: 16px;
    }

    .navItem {
        margin-right: 0px;
        margin-left: 0px;
    }

    .mail_text {
        font-size: 14px;
    }
}


@media (max-width: 1185px) {
    .menu {
        display: flex;
        justify-content: space-around;
        position: relative;
        width: 98% !important;
    }

    .navItem {
        margin-right: 2px;
        margin-left: 2px;
    }

    .navItem a {
        font-size: 16px;
    }

    .navItemLabel span {
        font-size: 16px;
    }

    .mail_text {
        font-size: 13px;
    }
}

@media (max-width: 1141px) {
    .menu {
        display: flex;
        justify-content: space-around;
        position: relative;
        width: 100% !important;
    }

    .navItem {
        /* margin-right: 4px; */
        /* margin-left: 4px; */
    }

    .navItem a {
        font-size: 16px;
    }

    .navItemLabel span {
        font-size: 16px;
    }

    .InnerList {
        min-width: 260px;
    }

    .mail_text {
        font-size: 13px;
    }
}

@media (max-width: 1085px) {
    .menu {
        display: flex;
        justify-content: space-around;
        position: relative;
        width: 100% !important;
    }

    .navItem {
        /* margin-right: 4px; */
        /* margin-left: 4px; */
    }

    .navItem a {
        font-size: 14px;
    }

    .navItemLabel span {
        font-size: 14px;
    }

    .InnerList {
        min-width: 240px;
    }

    .mail_text {
        font-size: 12px;
    }
}

/*# sourceMappingURL=navbar.css.map */