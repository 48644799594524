.form {
    margin-top: 20px;
    max-width: 432px;
}

.inputWrapper {
    margin-top: 20px;
}

.btn {
    margin-top: 20px;
    margin-left: auto;
}
