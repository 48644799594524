/* .ReportPartnerRow {
    border-bottom: 1px solid #cccccc;
} */
.PartnerHeader {
    display: flex;
    justify-content: space-between;
}
.GroupHeader {
    display: flex;
    justify-content: space-between;
}
.SwitchBlock {
    display: flex;
    /* width: calc(100% - 80px); */
    width: 298px;
    justify-content: space-between;
}
.GroupSwitchBlock {
    display: flex;
    width: 298px;
    justify-content: space-between;
    margin-left: -10px;
}
.TableCell {
    padding: 10px;
}
.TableSignMinus {
    font-size: 100% !important;
    cursor: pointer;
    position: absolute;
    padding-left: 20px;
}
.TableSignPlus {
    font-size: 100% !important;
    cursor: pointer;
    position: absolute;
    padding-left: 18px;
}
.TableSignGroupPlus {
    font-size: 100% !important;
    cursor: pointer;
    position: absolute;
    padding-left: 28px;
}
.TableSignGroupMinus {
    font-size: 100% !important;
    cursor: pointer;
    position: absolute;
    padding-left: 30px;
}
.GroupName {
    padding-left: 20px;
    width: 150px;
    height: 35px;
    display: flex;
    align-items: center;
}
