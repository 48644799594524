.ConvertContainer {
    border: 1px solid #D0D0D0;
    border-radius: 16px;
    padding: 30px;
    font-size: 12px;
}

.ConvertHeader {
    border-bottom: 1px solid #ECECEC;
    padding-bottom: 15px;
    margin-bottom: 20px;
}

.Rate {
    color: #A7A7A7;
    font-weight: 600;
    line-height: 18px;
}

.ConvertInfoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFF4F4;
    border-radius: 8px;
    padding: 28px;
}

.ConvertBlock label {
    color: #808080;
}

.CurrencySelect {
    display: flex;
    align-items: center;
}

.ConvertField {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 38px;
    border: 1px solid #383838;
    border-radius: 8px;
    padding: 0 18px;
}

.CurrencySelect :global(.label) {
    display: none;
}

.ConvertedAmount {
    display: inline-block;
    position: relative;
    padding-bottom: 3px;
}

.ConvertedAmount label:first-child {
    position: absolute;
    top: -34px;
    left: 0;
    width: 60px;
}

.ConvertedAmount label:nth-child(2) {
    position: absolute;
    bottom: -39px;
    left: 0;
    width: 145px;
}

.ConvertBlock .ConvertRate {
    font-size: 12px;
    line-height: 16px;
    color: #D0D0D0;
}

.convertBtnBlock {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    border-left: 1px solid #ECECEC;
    ;
}

.ConvertBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 169px;
    height: 38px;
    font-family: 'Bebas', sans-serif;
    font-size: 16px;
    line-height: 18px;
    color: #333333;
    background: #FFFFFF;
    border: 1px solid #333333;
    border-radius: 6px;
    padding: 9px 12px 7px;
    cursor: pointer;
}