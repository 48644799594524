.UploadContent {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.UploadForm {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
    height: 50%;
    max-width: 500px;
    max-height: 350px;
    background-color: #C4FECB;
    border-radius: 16px;
}
.UploadBtn {
    position: relative;
    padding-right: 25px;
}
.UploadBtn svg {
    position: absolute;
    top: 10px;
    right: 5px;
}
.UploadBtn svg path {
    fill: #FFFFFF;
}
.UploadFileName {
    color: #808080;
    margin-left: 15px;
}
.SelectBtn {
    padding-left: 20px;
    padding-right: 20px;
}
.ControlBlock .Message {
    padding: 25px;
    font-weight: 700;
    text-align: center;
}
