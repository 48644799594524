.General {}

.PersonBlock {}

.Partner {}

.Manager {}

.Label {

    color: #808080;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
}

.Value {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin: 8px 0 0 0;
}

.Requisites {
    margin-top: 24px;
}

.RequisitesContent {
    padding-right: 4.5%;
}

.ReqItem {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 8px;
}

.ReqItem>div {
    font-size: 12px;
}

.ReqItem .Name {
    font-weight: 500;
    min-width: 30%;
}

.ReqItem .ValueReq {
    color: #5a5959;
    word-break: break-word;
    text-align: right;
    width: 400px;
    font-size: 12px;
}

.PartnerLogo {
    width: 120px;
    border-radius: 5px;
}

.DeleteLogo,
.SaveLogo {
    color: red;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 7px;
}

.SaveLogo {
    color: blue;
}

.InputUpload {
    width: 140px;
}

.InputUpload.Loaded {
    border: none;
    padding: 0;
}

.InputUpload.Loaded label {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    color: red;
}

.InputUpload.Loaded label>svg {
    display: none;
}

.hoverStyle:hover {
    background-color: #FAFAFA !important;
}

.RadioBlockFlex {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 50px;
}

.AccountBtn {
    width: 100%;
    display: flex;
    align-items:  flex-end;
    justify-content: flex-start;
    gap: 80px;
}

.InputBlock {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: 36px;
    width: 100%;
}

.MainAccount {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 200px;
}

.UpdateSectionBtn {
    margin-right: 40px;
    margin-bottom: 3px;
    padding: 0px 22px;
    background-color: #383838;
    height: 36px;
    color: #FFF;
    font-family: 'Bebas';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    border-radius: 6px;
}