.SearchBlock {
  display: flex;
  max-width: 300px;
  position: relative;
  width: 100%;
  border: 1px solid #383838;
  border-radius: 8px;
}

.AutoComplitBlock {
  width: 315px;
  position: absolute;
  border-radius: 8px;
  padding: 5px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  z-index: 100;
  opacity: 1;
  line-height: 26px;
  border: 1px solid #383838;
  margin-top: 10px;
  max-height: 400px;
  overflow-y: scroll;
}

.AutoComplitElement {
  cursor: pointer;
  width: 100%;
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
}

.AutoComplitNone {
  display: none;
}

.AutoComplitElement:hover {
  cursor: pointer;
  background-color: hsl(0, 0%, 89%);
  width: 100%;
  border-radius: 8px;
}

.SearchBlock:hover {
  border: 1px solid hsl(0, 0%, 70%);
}

.SearchBlock input {
  height: 36px;
  padding: 0 0 0 35px;
  background: #FFFFFF;
}

.SearchBlock svg {
  position: absolute;
  top: 7px;
  left: 9px;
}

.SearchContainer label {
  display: inline-block;
  color: #202020;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 8px;
}

.ClearIcon {
  cursor: pointer;
}
