.TitleBlock {
    margin-bottom: 30px;
    padding: 0 !important;
}
.TitleBlock h2 {
    color: #000;
    font-family: Inter, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}
.ContentBlock {
    position: relative;
    min-height: 50vh;
}
.RecentReportList {
    display: flex;
}
.RecentReportDropDown {
    width: 350px;
    margin-right: 12px;
}
.RecentReportList :global(.label) {
    display: none;
}
.RecentReportList :global(.selPref__control ) {
    min-width: 350px;
}
.RecentReportList :global(.selPref__menu-list ) {
    min-width: 350px;
}
.SearchParamsBlock {
    display: flex;
}
.FilterItem {
    width: 330px;
    padding-right: 12px;
}
.FilterItem :global(.uiSelect__control),
.FilterItem :global(.uiSelect__menu),
.RecentReportList :global(.selPref__value-container ),
.RecentReportList :global(.selPref__placeholder ),
.DatePicker {
    font-size: 12px;
}
.FilterItem :global(.uiSelect__option) {
    padding-top: 7px;
    padding-bottom: 7px;
}
.FilterItem :global(.uiSelect__option input::before) {
    width: 18px;
    height: 18px;
    border-radius: 3px;
}
.FilterItem :global(.uiSelect__option input::after) {
    width: 14px;
    height: 14px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.DatePicker {
    border-radius: 8px;
    border: 1px solid #383838;
    background: #FFF;
    height: 38px;
}
.Label {
    display: block;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;
    padding-left: 5px;
}
.GetReportBtn {
    margin-top: 24px;
}
.TableReportBlock {
    margin-top: 25px;
    overflow: hidden;
}
.TableReportBlock * {
    box-sizing: border-box !important;
}
.firstColumn {
    width: 415px;
    padding-left: 9px;
    border-right: 1px solid #808080;
}
.reportColumn * {
    overflow: hidden;
}
.reportColumn:not(:first-child) {
    width: 511px;
    -webkit-transition: width 350ms;
    transition: width 350ms;
}
.reportColumn:not(:first-child).hiddenIncome,
.reportColumn:not(:first-child).hiddenExpenses {
    width: 414px;
}
.reportColumn:not(:first-child).hiddenIncome.hiddenExpenses {
    width: 318px;
}
.reportColumn:not(:last-child) {
    border-right: 1px solid #808080;
}
.reportColumn.hiddenColumn {
    width: 0 !important;
    border: none;
    transition:  width 350ms;
}
.totalCell {
    width: 80px;
    border-left: 1px solid #ECECEC;
    padding: 10px;
}
.currencyAmount, .convertedAmount, .marginIncomeAmount, .subTotalAmount {
    width: 96px;
    padding: 10px;
    border-right: 1px solid #ECECEC;
    border-bottom: 1px solid #ECECEC;
    text-align: right;
}
.convertedAmount, .marginIncomeAmount {
    width: 106px;
}
.reportColumn:last-child .marginIncomeAmount {
    border-right: none;
}
.subTotalAmount {
    width: 201px;
}
.lageFont {
    font-size: 170% !important;
}
.h100 {
    height: 100% !important;
}
.brNone {
    border-right: none !important;
}
.collapseIcon {
    cursor: pointer;
}
