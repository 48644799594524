.Item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 20px;
   /* padding: 16px 0 8px;
    border-bottom: 1px solid #ECECEC;
    box-sizing: border-box;*/
    margin-bottom: 20px;
    margin-top: 12px;
}
.Item >div{
    font-size: 14px;
    text-align: left;
}

.Item .Num{
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    margin-right: 10px;
}
.Item .Currency{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
}
.Item .Equal{
    margin-right: 15px;
    margin-left: 15px;
}
