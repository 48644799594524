.TitleBlock {
  margin-bottom: 30px;

}

.TitleBlock h2 {
  color: #000;
  font-family: 'Inter';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.ContentBlock {
  margin-top: 14px;
}

.FiltersBlock {}

.NoData {
  margin-top: 60px;
  padding: 30px 0 0;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #a6a6a6;
}

.DescSearch {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  margin-top: 16px;
  margin-bottom: 36px;
}