.ReportTableHeader {
    display: flex;
    border-radius: 8px;
    color: #ffffff;
    background-color: #2D8546;
    width: fit-content;
}
.ReportTableHeader * {
    color: #ffffff;
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    overflow: hidden;
}
.SwipePageBtn {
    font-family: Inter, serif;
    font-weight: 700;
    padding: 3px 15px;
    height: 27px;
    margin-right: 5px;
    color: #a6a6a6;

}
.TableHeaderCell:not(:first-child) {
    width: 511px;
    -webkit-transition: width 350ms;
    transition: width 350ms;
}
.TableHeaderCell:not(:first-child).hiddenIncome,
.TableHeaderCell:not(:first-child).hiddenExpenses {
    width: 414px;
}
.TableHeaderCell:not(:first-child).hiddenIncome.hiddenExpenses {
    width: 318px;
}
.TableHeaderCell:not(:last-child) {
    border-right: 1px solid #AFF1B7;
}
.TableHeaderCell.hiddenColumn {
    width: 0 !important;
    border: none;
    -webkit-transition:  width 350ms;
    transition:  width 350ms;
}
.InnerRow {
    display: flex;
    justify-content: center;
}
.InnerCell {
    opacity: 1;
    min-width: 96px;
    padding: 5px;
}
.SortIcon {
    margin-left: 10px;
    padding: 2px 0;
}
.IconGraph {
    float: right;
}
.InnerCell.hiddenCell > .IconGraph {
    display: none;
}
.iconDown {
    transform: rotate(180deg);
}
.h100 {
    height: 100% !important;
}
.smFont {
    font-size: 11px !important;
    font-weight: 400 !important;
    color: #61B073 !important;
}
.brGreen1 {
    border-right: 1px solid #AFF1B7;
}
.brGreen2 {
    border-right: 1px solid rgba(175, 241, 183, 0.60);
}
.TableHeaderCell:last-child .ReportTableHeader:last-child .brGreen2 {
    border-right: none;
}
.brGreen2bg {
    border-right-color: transparent;
}
.brGreen3 {
    border-right: 1px solid rgba(175, 241, 183, 0.40);
}
.bbGreen3 {
    border-bottom: 1px solid rgba(175, 241, 183, 0.40);
}
.bMainB {
    border-bottom: 1px solid #AFF1B7;
}
.bRound {
    border-radius: 8px;
}
.roundLT {
    border-top-left-radius: 8px 8px;
}
.roundRT {
    border-top-right-radius: 8px 8px;
}
.roundLB {
    border-bottom-left-radius: 8px 8px;
}
.roundRB {
    border-bottom-right-radius: 8px 8px;
}
.w106 {
    width: 105px;
}
/* Animation */
.ColumnEnter {
    width: 0;
    opacity: 0;
    border: none !important;
}
.ColumnEnterDone {
    width: 511px;
    opacity: 1;
    transition: width 350ms, opacity 350ms step-end;
}
.ColumnExit {
    opacity: 1;
    width: 511px;
}
.ColumnExitDone {
    width: 0;
    opacity: 0;
    transition:  width 350ms, opacity 350ms step-start;
    border: none !important;
}
