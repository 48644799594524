.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 32px;
}

.formHeader {
    display: flex;
    align-items: center;
}

.titleWrapper {
    display: flex;
    flex-direction: column;
}

.title {
    display: flex;
    align-items: center;
    gap: 8px;
}

.subTitle {
    color: #707070;
    font-size: 18px;
    line-height: 21px;
    margin-top: 6px;
}

.deleteBtn {
    margin-left: 130px;
}

.form {
    max-width: 430px;
}

.formTitle {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 20px;
}

.fieldGroup {
    margin-top: 24px;
}

.field {
    margin-bottom: 20px;
}

.datePicker {}

.buttons {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 20px;
}

.arrowIcon,
.calendarIcon {
    margin-right: 8px;
}