.App {
    display: block;
    width: 100%;
}

.App .Content {
    display: block;
    min-height: calc(100vh - 27px - 88px);
    padding: 115px 0 60px;
    position: relative;
    background-color: #FAFAFA;
}

.App .LoginContent {
    display: block;
    min-height: calc(100vh - 115px);
    padding: 0px;
    position: relative;
}

.resetPadding {
    padding: 0px !important;
}

.searchTextExist {
    overflow: hidden !important;
    height: 100% !important;
}

.searchTextNotExist {
    overflow: auto !important;
    height: fit-content !important;
}