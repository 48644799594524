.searchResultWrapper {
    margin-bottom: 16px;
}

.searchResultTitle {
    color: #808080;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 8px;
}

.links {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.searchResultLink {
    padding: 16px;
    color: #2D8546;
    border-radius: 8px;
    /* border: 1px solid #ECECEC; */
    border: 1px solid red;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    transition: background-color 0.2s ease-in-out;

}

.searchResultLink:hover {
    background-color: #2D8546;
    color: #fff;
    background-image: url(../../../assets/img/findSectionIcon/arrow-icon.png);
    background-repeat: no-repeat;
    background-position: 98.5% 50%;
}