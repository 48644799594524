.ProjectGroupRow {
    padding: 12px 0;
    border-bottom: 1px solid #F1F1F1;
}
.ProjectGroupRowEdit {
    padding: 12px 0;
    margin: 0 0 0 15px;
    border-bottom: 1px solid #F1F1F1;
}
.ProjectGroupRow:first-child {
    border-top: 1px solid #F1F1F1;;
}
.ProjectGroupRow > * {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
}
.ProjectGroupIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 31px;
    width: 30px;
    border-radius: 4px;
    background-color: #AFF1B7;
    margin-right: 15px;
}
.Button {
    display: flex;
    align-items: center;
    border: none;
    color: #999999;
    background-color: transparent;
    height: 34px;
    font-family: unset;
    font-size: 13px;
    line-height: 92%;
    font-weight: 400;
}
.Button.Delete svg > path {
    stroke: #999999;
}
.Button.Edit:hover svg > path {
    fill: #2D8546;
}
.Button.Delete:hover svg > path {
    stroke: #B23333;
}
.InputName {
    border: 1px solid #383838;
    border-radius: 8px;
    height: 38px;
    width: 50%;
    padding: 2px 12px;
}
.InputName:hover {
    border: 1px solid hsl(0, 0%, 70%);
}
.InputName:focus, .InputName:active {
    box-shadow: none;
    outline: none;
}
.InputDescription {
    border: 1px solid #383838;
    border-radius: 8px;
    height: 38px;
    width: 100%;
    padding: 2px 12px;
    margin: 0 10px 0 150px;
}
.InputDescription:hover {
    border: 1px solid hsl(0, 0%, 70%);
}
.InputDescription:focus, .InputDescription:active {
    box-shadow: none;
    outline: none;
}
.Category {
    width: 600px;
}
.EditInputs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 900px;
}
.CategoryName {
    width: 550px;
    display: flex;
    justify-content: left;
    align-items: flex-start;
    padding: 0 10px 0 10px;
    overflow-wrap: anywhere;
}
.CategoryDescription {
    width: 100%;
    overflow-wrap: anywhere;
    padding: 0 10px 0 50px;
    display: flex;
    justify-content: left;
    align-items: flex-start;
}
