.wrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 4px 0 #0000000D;
    background-color: #ffffff;
    border-radius: 20px;
    margin-top: 20px;
    overflow-x: hidden;
}

.tableWrapper {
    overflow: auto;
    margin-top: 20px;
}

.title {
    font-size: 11px;
    color: #707070;
}

.title span {
    font-size: 12px;
    color: #222222;
    margin-left: 14px;
}

.table {
    min-width: 100%;
    border-collapse: collapse;
    table-layout: auto;
}

.thead {
    text-align: left;
}

.thead th {
    font-size: 11px;
    color: #8D838F;
    font-weight: 400;
    text-align: left;
    text-transform: uppercase;
    padding: 12px 16px;
}

.tbody {
    font-size: 12px;
    font-weight: 400;
    color: #222222;
}

.tbody td {
    padding: 8px 16px;
    text-wrap: nowrap;
}

.nowrap {
    white-space: nowrap;
}

.tbody tr:nth-child(odd) {
    background-color: #F8F8F7;
}

.empty {
    text-align: center;
    padding: 20px;
}
