.ModalWrapper {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 15px;
    z-index: 1000;
    overflow: auto;
    display: flex;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0s linear;
    transition-delay: 0.1s;
}
.ModalWrapper.Open {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0s linear;
}
.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(77, 86, 108, 0.3);
    opacity: 0;
    transition: opacity .1s;
    z-index: 1;
}


.OverlayEnterActive {
    opacity: 1;
    transition: opacity .1s;
}
.OverlayEnterDone {
    opacity: 1;
}
.OverlayExit {
    opacity: 1;
}
.OverlayExitActive {
    opacity: 0;
    transition: opacity .4s;
}

/*Popup*/
.Popup {
    max-width: 688px;
    margin: auto;
    perspective-origin: 50% 50%;
    width: 100vw;
    perspective: 100px;
    z-index: 2;
}


.PopupData {
    position: relative;
    background: #ffffff;
    border-radius: 24px;
    opacity: 0;
    transform: scale(1.3);
    transition: opacity 0.3s, transform 0.3s ease-in-out;
    font-size: 16px;
    min-height: 170px;
}
.Big{

}
.Popup.Big .PopupData{
    max-width: 688px;
    padding:24px;
}
.Popup.Bigger .PopupData{
    max-width: 1100px;
    padding:24px;
}.Popup.Bigger {
    max-width: 1100px;
    padding:24px;
}
.Popup.Bigger .PopupClose{
    display: none;
}
.Popup.Big .PopupClose{
    display: none;
}
.Popup.Small {
    max-width: 240px;
}
.Popup.Small .PopupData {
    min-height: 85px;
}
.Popup.Middle{
    max-width: 400px;
}
.Popup.MiddleLg{
    max-width: 582px;
}
.PopupDataEnterActive {
    opacity: 1;
    transform: scale(1);
    transition: opacity 0.3s, transform 0.3s ease-in-out;
}
.PopupDataEnterDone {
    opacity: 1;
    transform: scale(1);
}
.PopupDataExit {
    opacity: 0;
    transform: scale(1.3);
    transition: opacity 0.1s, transform 0.1s ease-in-out;
}
.PopupDataExitActive {
    opacity: 0;
    transform: scale(1.3);
}

.PopupData .PopupDataHeader {
    padding: 20px 20px 10px 20px;
}
.PopupData .PopupDataLabel {
    font-size: 20px;
    font-weight: 600;
    color: #151515;
}
.PopupData .PopupDataContent {
    padding: 20px 20px 20px 20px;
}
.PopupData .PopupDataHeader+.PopupDataContent{
    padding-top: 10px;
}
.PopupData .PopupDataFooter {
    padding: 10px 0 20px 0;
    font-size: 14px;
    color: #B0BFC6;
}
.PopupData .PopupDataFooter+.PopupDataContent{
    padding-bottom: 10px;
}

.PopupClose {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #B0BFC6;
    font-size: 20px;
    z-index: 10;
    display: none;
}


@media(max-width: 991px){
    .ModalWrapper{
        padding: 5px;
    }
    .PopupData .PopupDataLabel {
        font-size: 16px;
    }
    .PopupData .PopupDataHeader {
        padding: 15px 15px 5px 15px;
    }
    .PopupData .PopupDataContent {
        padding: 15px;
    }
    .PopupData .PopupDataHeader+.PopupDataContent{
        padding-top: 5px;
    }
    .PopupData .PopupDataFooter {
        padding: 5px 0 15px 0;
        font-size: 12px;
    }
    .PopupData {
        font-size: 14px;
    }
}

@media (min-width: 576px) {
    .PopupData .PopupDataContent {
        height: auto!important;
    }
}
@media (max-width: 575px) {
    .ModalWrapper {
        padding: 54px 0 0;
    }
    .PopupClose {
        top: 10px;
        right: 10px;
    }
    .Popup {
        max-width: 100%;
        max-height: calc(100% - 54px);
        perspective-origin: 0;
        perspective: none;
        position: fixed;
        bottom: 0;
        margin: 0;
    }
    .Popup.Small {
        max-width: 100%;
    }
    .Popup.Small .PopupData {
        min-height: 85px;
    }
    .PopupData {
        border-radius: 13px 13px 0 0;
        opacity: 1;
        transform: scale(1) translateY(100%);
    }
    .Popup .PopupData .OverlayData .PopupDataHeader {
        padding: 0 15px;
        display: flex;
        height: 40px;
        align-items: center;
    }
    .Popup .PopupData .OverlayData .PopupDataContent {
        padding: 10px 15px 20px;
        overflow: auto;
    }

    .PopupDataEnterActive {
        opacity: 1;
        transform: scale(1) translateY(0);
        transition: transform 0.3s ease-in-out;
    }
    .PopupDataEnterDone {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
    .PopupDataExit {
        opacity: 1;
        transform: scale(1) translateY(100%);
        transition: transform 0.1s ease-in-out;
    }
    .PopupDataExitActive {
        opacity: 1;
        transform: scale(1) translateY(100%);
    }
}

@supports (padding-top: env(safe-area-inset-bottom)) {
    .Popup {
        --safe-area-inset-top: env(safe-area-inset-bottom);
        bottom: var(--safe-area-inset-top)!important;
    }
}
