.wrapper {
    margin-top: 32px;
}

.title {
    display: flex;
    align-items: center;
    gap: 8px;
}

.btn {
    margin: 20px 0;
}
