.AdvCardContent {
    position: relative;
    font-size: 14px;
    line-height: 16px;
    color: #202020;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    padding: 24px;
}

.AdvCardContent h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
}

.SectionDivider {
    margin: 35px 0;
    border-bottom: solid thin #ECECEC;
}

.BackLink {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
}

.BackLink:hover {
    font-weight: 700;
}

.Title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px;
}

.Save {
    position: absolute;
    top: -78px;
    right: 18px;
}

.AdvCardContent textarea {
    width: 100%;
    background: #F6F6F6;
    border-radius: 16px;
    border: none;
    padding: 15px;
    resize: none;
}

.AdvCardContent textarea::placeholder {
    color: #A7A7A7;
}

.TabLinks {
    font-weight: 500;
    /*  */
    font-size: 12px;
}

.TabLinks>li {
    padding: 8px 16px;
    color: #808080;
    cursor: pointer;
}

.TabLinks>li.Active {
    color: #202020;
    border-right: 5px solid #2D8546;
    /*  */
    font-size: 12px;
}

.AdvTabContent {
    font-weight: 400;
    /*  */
    font-size: 12px;
    line-height: 16px;
    padding-left: 15px;
}

.AdvTabContent .FieldLabel {
    color: #808080;
}

.DocList,
.DocList>div {
    width: 100%;
}

.LightBold {
    font-weight: 500;
}

.AdvCardInput {
    width: 100%;
    margin-bottom: 0;
}

.AdvCardInput input {
    width: 100%;
    margin-top: 0;
}

.AdvCardContent :global(.label) {
    margin-bottom: 0;
}

.AdvCardContent :global(.selectModal) {
    width: 259px;
}

.InputBlock {}

.AdvCommonData input {
    width: 259px;
}

.RadioBlockFlex label,
.RadioBlock label {
    color: #202020;
    font-weight: 300;
}

.RadioBlockFlex {
    width: 259px;
    display: flex;
    align-items: center;
}

.RadioBlockFlex>div {
    margin-top: 0;
}

.RadioBlockFlex>div:first-child,
.RadioBlock>div {
    margin-left: 5px;
}

.AdvLogo {
    margin: auto;
    width: 233px;
    height: 233px;
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D9D9D9;
}

.uploadBlock {
    display: inline-block;
}

.uploadBlock>div {
    width: fit-content;
}

.uploadBlock input {
    display: none;
}

.uploadBlock .FieldLabel {
    display: block;
    margin-bottom: 15px;
}

.uploadBlock a {
    display: inline-block;
    width: 45px;
    margin-right: 25px;
}

.FieldError {
    color: red;
    margin-top: 5px;
    margin-left: 15px;
}

.BorderRight {
    border-right: 1px solid #F6F6F6;
}

.advertiserCard {
    min-height: 440px;
}