.react-datepicker{
    box-shadow: none;
}
.react-datepicker__close-icon::after {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
    margin-top: -1px;
    align-content: center;
}

.react-datepicker-popper{
    width: 500px;
}

.label{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    position: relative;
    bottom: -12px;
    color: #FFFFFF;

}
 .inputBillingRange .react-datepicker .react-datepicker__day{
    color: #202020;
     width: 2.032rem;
     margin: 0;
}
.inputBillingRange .react-datepicker__week div:nth-child(6),
.inputBillingRange .react-datepicker__week div:nth-child(7){
    color: #7D40FF;
}

 .inputBillingRange .react-datepicker .react-datepicker__day--disabled{
    opacity: 0.5;
    color: #202020;

}
 .inputBillingRange .react-datepicker .react-datepicker__day--in-range{
    background: #ECECEC ;
     border-radius: 0;
}
 .inputBillingRange .react-datepicker .react-datepicker__day--range-start,
 .inputBillingRange .react-datepicker  .react-datepicker__day--range-end {
    background: #202020 !important;
     color: #FFFFFF !important;
     border-radius: 4px;
}
 .inputBillingRange
 .react-datepicker
 .react-datepicker__day--range-end.react-datepicker__week div:nth-child(6),
 .inputBillingRange
 .react-datepicker
 .react-datepicker__day--range-end.react-datepicker__week div:nth-child(7){
     background: #ECECEC ;
 }
.inputBillingRange .react-datepicker .react-datepicker__day--in-selecting-range{
     background: #ECECEC ;
     border-radius: 0;
 }
.inputBillingRange .react-datepicker .react-datepicker__day--range-start.react-datepicker__day--in-selecting-range,
.inputBillingRange .react-datepicker  .react-datepicker__day--range-end.react-datepicker__day--in-selecting-range {
    background: #202020 !important;
    color: #FFFFFF;
    border-radius: 4px;
}
