.HistoryList{
   width: 100%;
}
.Active .HistoryList {
    display: block;
}
.HistoryBlock{
    width: 100%;

    margin-top: -4px;
}
.HistoryBlock h4{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}

.BillingPartLabel{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    min-height: 24px;
    margin-top: 16px;
    padding-right: 0;
    border-bottom: 1px solid #F6F6F6;
    padding-bottom: 8px;
    box-sizing: border-box;
    font-size: 14px;

}
 .Number{
    width: 30%;
}

.Title{
    width: 30%;
    font-size: 14px;
}
.Status{
    width: 30%;
    font-size: 14px;
}

.HistoryBlock.Active .Drop  svg{
   transform: rotate(180deg) !important;
}

.ListBlock{
    padding: 15px 0 15px 15px;
}