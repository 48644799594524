.SearchBlock {
  max-width: 300px;
  position: relative;
  width: 100%;
  border: 1px solid #383838;
  border-radius: 8px;
}

.SearchBlock:hover {
  border: 1px solid hsl(0, 0%, 70%);
}

.SearchBlock input {
  height: 36px;
  padding: 0 0 0 35px;
  background: #FFFFFF;
}

.SearchBlock svg {
  position: absolute;
  top: 7px;
  left: 9px;
}

.SearchContainer label {
  display: inline-block;
  color: #202020;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 8px;
}