.selPref__control {
  background: #FFFFFF !important;
  border-radius: 8px !important;
  padding: 2px 12px;
  /* box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1) !important; */
}

.selPref__menu {
  background: #FFFFFF !important;
  border-radius: 8px !important;
  margin-top: 8px !important;
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1) !important;
}

.selPref__indicator svg {
  fill: #000 !important;
}

.selPref__indicator-separator {
  background: none !important;
}

.selPref__option {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #202020 !important;
}

.selPref__single-value {
  color: #808080 !important;
  font-weight: 400 !important;
  /* font-size: 14px !important; */
  line-height: 16px !important;
}

.selPref__placeholder {
  font-size: 14px;
}

.selPref__control--is-focused {
  /*box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1) !important;*/
}

.selPref__option--is-focused {
  background: #F3F3F3 !important;
  color: #2D8546 !important;

}

.selPref__option--is-selected {
  background: #F3F3F3 !important;
  color: #2D8546 !important;
}

.label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 14px;
  color: #808080;
}

.selectModal {
  height: 40px !important;
}

.selPref__indicator {
  padding: 0 !important;
}