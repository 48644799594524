.ListHeader {
    padding: 15px;
    border-bottom: 1px solid #ECECEC;
    background: #2D8546;
    border-radius: 8px;
    position: relative;
    margin: 0px !important;
    padding-right: 0px;
}

.ListHeader>div {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    margin: 0;
    display: flex;

}

.ListHeader>div>span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    margin: 0;
    display: flex;

}

.Billings_Content {
    margin-top: 40px;
    min-height: 600px;
}

.Name {
    width: 20%;
}

.Id {
    width: 20%;

}

.Manager {
    width: 16%;
}

.Offers {
    width: 7%;
}

.Site {
    width: 18%;
}

.Country {
    width: 7%
}

.Status {
    width: 9%
}

.Btn {
    width: 3%;
}

.Active .Down {
    background: url("../../../../assets/img/arrow.svg");
    width: 20px;
    height: 20px;
}

.Active .Up {
    background: url("../../../../assets/img/arrow.svg");
    transform: rotate(180deg);
    width: 20px;
    height: 20px;
}

.Active {
    color: #7D40FF !important;
}

.Active .Down {
    background: url("../../../../assets/img/arrow.svg");
    width: 20px;
    height: 20px;
}

.Active .Up {
    background: url("../../../../assets/img/arrow.svg");
    transform: rotate(180deg);
    width: 20px;
    height: 20px;
}

.Icon {}

.NoData {
    margin-top: 80px;
    text-align: center;
}

.NoData h1 {
    font-size: 20px;
}