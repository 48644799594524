.Item{
    min-height: 57px;
    border-bottom: 1px solid  #ECECEC;
    transition: ease-out 0.3s;
}
.Item.Active{
    background: #F6F6F6;
}
.Item:hover{
    background: #F6F6F6;
}
.Td{
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #808080;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
    padding: 8px 8px 8px 0;
}
.Td >div{
    margin-top: -4px;
    margin-left: 8px;
}
.Item{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.Td.Checkbox{
    width: 2.8%;
}
.Td.Checkbox>div{
   display: flex;
   align-items: center;
}
.Td.Num{
    width: 2.6%;
}
.Td.Id{
    width: 11%;
    padding: 8px 0;
}
.Td.Id >div{
    width: 100%;
    margin: 0;
}
.Td.Offer{
    width: 15.5%;
}
.Td.ClickId{
    width: 13.2%;

}
.Td.OrderId{
    width: 18.2%;
    word-break:break-all;
}
.Td.Revenue{
    width: 6.7%;
    padding-left: 8px;
}
.Td.Payments{
    width: 6.7%;
}
.Td.Status{
    width: 11.8%;
}
.Td.Date{
    width: 6.5%;
}
.Td.Btn{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Settings{
    cursor: pointer;
    transition: ease-out 0.3s;
}
.Settings svg{
    transition: ease-out 0.3s;
}
.Settings:hover svg{
    fill: #202020;
}
.Td svg, .Td span{
    fill: #a6a6a6;
}
.Td .ActiveHidden span{
    margin-top: -4px;
    left: 0;
    cursor: pointer;
}
.Td .ActiveHidden.Active svg{
    transform: rotate(180deg);
    fill: #202020;
}
.Td .ActiveHidden{
    margin-left: 0;
}
.Td  .Settings{
    margin-top: 0;
}
.HiddenContainer{
    display: flex;
    justify-content: flex-end;
    width: 100%;

}
.HiddenContainer > div{

    width: 50%;
    margin-right: 48px;
    margin-bottom: 40px;
    display: none;
}
.HiddenContainer.Active >div{
    display: flex;
}
.HiddenContainer .Label{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #808080;
    margin-right: 24px;
}
.HiddenContainer .Comment{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}
