.Status {

  display: flex;
  align-items: center;
  font-size: 12px;
}

.Status svg {
  margin-right: 8px;
  width: 16px;
  height: 16px;
  margin-top: 6px;
}

.Status.Big svg {
  width: 24px;
  height: 24px;

}

.Finished,
.PaidPartly,
.Pending {
  color: rgba(56, 188, 69, 1);
}

.Declined {
  color: #FF4040;
}

.Waiting {
  color: #a6a6a6;
}