.select__control {
    background: #FFFFFF !important;
    border-radius: 8px !important;
    padding: 2px 12px !important;
    border: 1px solid #333333 !important;
    height: 40px !important;
}

.select__menu {
    background: #FFFFFF !important;
    border-radius: 8px !important;
    margin-top: 8px !important;
    overflow: hidden;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1) !important;
    z-index: 1000 !important;
}

.select__indicator svg {
    fill: #000 !important;
}

.select__indicator-separator {
    background: none !important;
}

.select__option {
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    color: #202020 !important;
}

.select__single-value {
    color: #808080 !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 16px !important;
}

.select__value-container {
    padding: 0 !important;
}

.select__placeholder {
    font-size: 14px;
}

.select__control--is-focused {
    /*box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1) !important;*/
}

.select__option--is-focused {
    background: #F3F3F3 !important;
    color: #2D8546 !important;

}

.select__option--is-selected {
    background: #F3F3F3 !important;
    color: #2D8546 !important;
}

.select__indicator {
    padding: 0 !important;
}
