.Label {
  display: flex;
  font-size: 12px;
  line-height: 16px;
  border-radius: 10px;
  padding: 5px 5px;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.Green {
  color: #2D8546;
  background-color: #C4FECB;
}

.Blue {
  color: #2A829D;
  background-color: #C4F0FE;
}

.Orange {
  color: #2A829D;
  background-color: #ffda76;
}

.GreenBorder {
  color: #2D8546;
  border: 1px solid #AFF1B7;
}

.Grey {
  color: #808080;
  background-color: #E8E8E8;
}

.Yellow {
  color: #C19933;
  background-color: #FFEDBD;
}

.Red {
  color: #B23333;
  background-color: #FFD0D0;
}

.info_icon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0px;
  right: -26px;
  cursor: pointer;
  background-image: url('../../../../../assets/img/info-icon.svg');
}

.info_icon:hover {
  background-image: url('../../../../../assets/img/info-icon-hover.svg');
}

.tooltip {
  padding: 8px;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.15);
  position: absolute;
  min-width: 134px;
  color: #B23333;
  font-family: 'Inter';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  box-sizing: border-box;
  left: 16px;
  word-break: break-word;
  text-align: center;
}