.wrapper {
    display: flex;
    align-items: center;
}

.label {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin-right: 8px;
}