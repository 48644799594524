.inputField {
  background: #F6F6F6;
  border-radius: 16px;
  width: 100%;
  height: 40px;
  border: none;
  box-sizing: border-box;
  padding: 8px 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #202020;
  padding-right: 10px;
}

.inputField:focus-visible {
  border: none;
  outline: none;
}

.inputField[data-lpignore="true"]+[data-lastpass-icon-root="true"] {
  display: none;
}