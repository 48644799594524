.container {
  /* margin-top: 40px; */
  margin-bottom: 24px;
}

.titleContent {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.titleContent img {
  margin-right: 12px;
}

.title {
  color: #202020;
  font-family: 'Inter';
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}