.addIcon {
    margin-right: 14px;
}

.editIcon {
    width: 100%;
    height: auto;
}

.editIcon path {
    fill: black;
}

.addBtn {
    width: 180px;
}

.editBtn {
    border: none;
    background-color: transparent;
    width: 27px;
    height: 27px;
    margin-right: 14px;
    cursor: pointer;
}

.controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
