.titleWrapper {
    display: flex;
    flex-direction: column;
}

.formHeader {
    display: flex;
    align-items: center;
}

.title {
    margin-top: 32px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.subTitle {
    color: #707070;
    font-size: 18px;
    line-height: 21px;
    margin-top: 6px;
}

.field {
    margin-bottom: 20px;
}

.form {
    margin-top: 20px;
    max-width: 430px;
}

.buttons {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 20px;
}

.deleteBtn {
    margin-left: 130px;
}
