.InpBlock{
    margin-bottom: 0;
    background: #FFFFFF;
    border: 1px solid #333333;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 9px 12px 7px 16px;
    color: #333333;
    margin-right: 8px;
}
.InpBlock label{
    color: #333333;
    font-family: 'Bebas', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
}
.InpBlock svg{
    width: 16px;
    height: 16px;
    position:relative;
    top: 2px;
}
.InpBlock svg > path {
    fill: #333333;
}
.InpBlock input{
    margin-top: 8px;
    height:0;
    opacity: 0;
    position: absolute;
 }
.InputField{
    background: #FFFFFF;
    border-radius: 16px;
    width: 100%;
    height: 40px;
    border: none;
    box-sizing: border-box;
    padding: 8px 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #202020;
}
.Login input{
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 32px;
    border: none;
    border-bottom:2px solid  rgba(208, 208, 208, 1);
    padding-bottom: 16px;
    background: none;
    outline: none;
    opacity: 1;
    width: 100%;
    border-radius: 0;
    padding-left: 0;
    margin: 11px 0;
}
.Login input:focus-visible{
    border:none;
    outline: none;
    border-bottom:2px solid  rgba(208, 208, 208, 1);
}
.Login{
    position: relative;
    width: 100%;
    margin-bottom: 16px;
    padding: 0;
}
.Login div{
    bottom: -10px;
    left:0;
}
.Login input::placeholder{
    opacity: 0.7;
}
