.Checkbox {
    margin-top: 8px;
    margin-left: 20px;
}
.Checkbox label{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-top: 8px;
    margin-left: 8px;
}

.Checkbox input,
label {
    cursor: pointer;
}

.Checkbox input {
    position: relative;
    height: 16px;
    width: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    top:3px;
}

.Checkbox input::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #a6a6a6;
}

.Checkbox input:checked::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    background: url("../../../../assets/img/checkbox-fiol.svg");
    transform: translate(-50%, -50%);
    visibility: visible;
}
.Checkbox.Green{
    background: #F6F6F6;
    border-radius: 16px;
    padding: 12px 16px;
}