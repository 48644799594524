.Item {
    padding-top: 12px;
    border-bottom: 1px solid #ECECEC;
    padding-bottom: 12px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 4px 15px;
    margin: 0px;
    padding-right: 0px;
}

.Item:hover {
    background: #ECECEC;
}

.Dash {
    color: #999999;
    padding-left: 5px;
}

.Item>div {
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.Col {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #202020;
    margin: 0;
}

.Name {
    width: 30%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.Title {
    color: #808080;
    padding-right: 30px;
    line-height: 18px;
}

.Id {
    width: 4%;
}

.Manager {
    width: 19%;
    /* padding-left: 5px; */
}

.Advertiser {
    width: 25%;
    /* padding-left: 5px; */
}

.Status {
    width: 9%;
}

.Privacy {
    width: 9%;
}

.Btn {
    width: 3%;
}

.Name img {
    width: 80px;
    height: 40px;
    object-fit: contain;
    border-radius: 4px;
    object-position: center;
    margin-right: 8px;
}

.Alert {
    font-size: 12px;
    color: #FF4040;
    margin-left: 10px;

}

.Td svg,
.Td span {
    fill: #a6a6a6;
}

.Td .ActiveHidden span {
    margin-top: -4px;
    left: 0;
    cursor: pointer;
}

.Td .ActiveHidden.Active svg {
    transform: rotate(180deg);
    fill: #202020;
}

.Td .ActiveHidden {
    margin-left: 0;
}

.Td .Settings {
    margin-top: 0;
}

.Td.Btn {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.Settings {
    cursor: pointer;
    transition: ease-out 0.3s;
}

.Settings svg {
    transition: ease-out 0.3s;
}

.Settings:hover svg {
    fill: #202020;
}

.Td>div {
    margin-top: -4px;
    margin-left: 8px;
}

.archive {
    padding: 2px 6px;
    border-radius: 10px;
    background: #E8E8E8;
    color: #808080;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}