.ProjectRow {
    display: flex;
    align-items: center;
    padding-top: 12px;
    border-top: 1px solid #F1F1F1;
}
.ProjectRow:first-child {
    margin-top: 12px;
}
.ProjectRow:not(:last-child) {
    padding-bottom: 12px;
}
.ProjectIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 31px;
    width: 30px;
    border-radius: 4px;
    background-color: #F0F1AF;
    margin-left: 72px;
    margin-right: 15px;
}
.PartnerData {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    margin-left: 25px;
}
.ID {
    color: #808080;
}
.Button, .EditLink {
    font-size: 12px;
    font-weight: 400;
}
.Button {
    display: flex;
    align-items: center;
    border: none;
    color: #999999;
    background-color: transparent;
    height: 34px;
    font-family: unset;
}
.Button svg > path {
    stroke: #999999;
}
.Button:hover svg > path {
    stroke: #B23333;
}
.EditLink {
    display: flex;
    align-items: center;
    height: 34px;
    margin-right: 15px;
    color: #999999;
}
.EditLink:hover svg > path {
    fill: #2D8546;
}
