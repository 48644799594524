.Status{

    display: flex;
    align-items: center;
    font-size: 14px;
}
.Status svg{
    margin-right: 8px;
    width: 12px;
    height: 12px;
}