.UserSetting {
    margin-top: 0;
}

.UserSetting .List {
    margin-top: 0;
    padding-top: 16px;
    border-right: 1px solid #F6F6F6;
    padding-right: 24px;
}

.LeftBlock {
    padding-right: 0;
    flex: 0 0 23%;
    max-width: 23.4%;

}


.LeftBlock>div {
    padding: 0;

}

.LeftBlock>div>div ul {
    margin-top: 0;
    border-right: 1px solid #F6F6F6;
    padding-top: 17px;
    min-height: 500px;
}

.LeftBlock>div>div li {
    margin-right: 20px;

}

.RightBlock {
    padding-left: 0;
    flex: 0 0 76%;
    max-width: 76%;
}

.RightBlock>div {
    padding-left: 15px;
}

.UserContentBlock {
    margin-left: 25px;
}

.UserSetting .List li {
    padding-right: 25px;
    margin-bottom: 8px;


}

.UserSetting .List .Item.active a {
    background: #7D40FF;


}

.UserSetting li.active svg {
    fill: #FFFFFF;

}

.UserSetting li.active .usernameBlock {
    color: #FFFFFF;
}

.ControlPanel {
    margin-top: 41px;
}

.TabBlock .tab {
    margin-right: 10px;
}

.BtnBlock {
    text-align: right;
}

.TitleBlock {
    margin-top: 32px;
}

.TitleBlock h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
}

.UserFieldBlock {
    margin-top: 24px;
    display: none;
}

.UserFieldBlock input {
    font-size: 12px;
}

.UserFieldBlock.Active {

    display: block;
}

.UserSetting .radioBlock {
    margin-top: 10px;
}

.UserSetting .radioBlock .title {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

.UserSetting .radioBlock .title {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}


.UserSetting .radioBlock .row {
    margin-left: 0;
}

.userSetting .radioBlock .inputRadio {
    margin-right: 18px;
}

.BtnSection {
    border-bottom: 1px solid #F6F6F6;
    padding-bottom: 16px;
    display: flex;
    justify-content: flex-end;
    height: 57px;
}

.InfoTab {}

.DeleteBtn {
    margin-left: 25px;
    background: #FF4040;
}