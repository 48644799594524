.TitleBlock {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    padding: 0 !important;
}
.ContentBlock {}
.Label {
    display: block;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;
    padding-left: 5px;
}
.LabelDesc {
    padding-left: 220px;
    font-weight: 600;
    font-size: 16px;
}
.LabelName {
    padding-left: 40px;
    font-size: 16px;
    font-weight: 600;
}
.Input {
    border: 1px solid #383838;
    border-radius: 8px;
    height: 38px;
    width: 100%;
    padding: 2px 12px;
}
.Input:hover {
    border: 1px solid hsl(0, 0%, 70%);
}
.Input:focus, .Input:active {
    box-shadow: none;
    outline: none;
}
