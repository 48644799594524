.wrapper {
    display: flex;
    align-items: center;
}

.checkbox {
    width: 24px;
    height: 24px;
    border: 1px solid #333333;
}

.label {
    font-size: 14px;
    line-height: 16px;
    margin-left: 8px;
}
