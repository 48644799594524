.InputRadio {
    margin-top: 8px;
    margin-left: 20px;
}

.InputRadio label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-top: 8px;
    margin-left: 8px;
}

.InputRadio input,
label {
    cursor: pointer;
}

.InputRadio input {
    position: relative;
    height: 16px;
    width: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    top: 3px;
}

.InputRadio input::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #808080;
}

.InputRadio input:checked::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #2D8546;
    transform: translate(-50%, -50%);
    visibility: visible;
}

.InputRadio.Green {
    background: #F6F6F6;
    border-radius: 6px;
    padding: 12px 16px;
}

.InputRadio.Green.Active {
    background: #2D8546;
}

.InputRadio.Green.Active label {
    color: #FFFFFF;
    font-size: 12px;
}

.InputRadio.Green input:checked::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    border-radius: 0;
    background-color: #2D8546;
    transform: translate(-50%, -50%);
    visibility: visible;
    background: url("../../../../assets/img/checkbox.svg");
    border: none
}

.InputRadio.Green input::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    border-radius: 0;
    transform: translate(-50%, -50%);
    border: 1px solid #808080;
    background: transparent;
}

.InputRadio.Green input:checked::before {
    border: none;
}