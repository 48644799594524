.form {
    display: flex;
    align-items: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.field {
    width: 300px;
}

.calendarIcon {
    margin-right: 8px;
}
