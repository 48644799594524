.innerWrapper {
    display: flex;
}

.sidebar {
    padding-top: 70px;
}

.tabContent {
    flex: 1;
    padding-left: 32px;
}

.loaderWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}