.container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    cursor: pointer;
}

.title {
    color: #2D8546;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.container:hover .title {
    font-weight: 500;
}

.date {
    color: #999;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.title_zone {
    display: flex;
    align-items: center;
}

.dot {
    width: 4px !important;
    height: 4px !important;
    margin-right: 4px;
    background-color: #2D8546;
    border-radius: 50px;
}