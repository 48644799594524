.button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Bebas', sans-serif;
    height: 38px;
    font-size: 16px;
}

.primary,
.secondary,
.danger {
    padding: 10px 26px;
    border-radius: 6px;
    cursor: pointer;
}

.primary {
    background-color: #333333;
    color: #FFFFFF;
    border: none;
}

.secondary {
    background-color: #FFFFFF;
    color: #333333;
    border: 2px solid #333333;
}

.danger {
    background-color: #FFFFFF;
    color: #B23333;
    border: 2px solid #B23333;
}

.button:disabled {
    background-color: #FFFFFF;
    color: #D9D9D9;
    border: 2px solid #D9D9D9;
    cursor: not-allowed;
}
