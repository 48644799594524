.tableWrapper {
    max-height: 800px;
    overflow-y: auto;
    margin-top: 20px;
    position: relative;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.thead {
    text-align: left;
    padding: 16px 26px;
}

.thead th {
    background-color: #2D8546;
    color: white;
    font-size: 14px;
    font-weight: 600;
    padding: 16px 26px;
    position: sticky;
    top: 0;
    z-index: 1;
}

.thead th:first-child {
    border-radius: 10px 0 0 10px;
}

.thead th:last-child {
    border-radius: 0 10px 10px 0;
}

.tbody td {
    font-size: 14px;
    padding: 22px 26px;
    border-bottom: 1px solid #D9D9D9;
}

.empty {
    text-align: center;
    padding: 20px;
}