.MultiSelect {
  height: 38px;
}

.MultiSelect :global(.uiSelect__control) {
  border: 1px solid #383838 !important;
  border-radius: 8px !important;
}

.MultiSelect :global(.uiSelect__value-container) {
  display: flex;
  flex-wrap: nowrap;
  font-size: 14px;
  line-height: 16px;
  padding-right: 0;
}

.MultiSelect :global(.uiSelect__option) {
  font-size: 14px;
  line-height: 16px;
}

.MultiSelect :global(.uiSelect__indicator-separator) {
  display: none;
}

.MultiSelect :global(.uiSelect__indicator svg) {
  fill: #A7A7A7;
  transform: scaleX(80%) scaleY(80%);
}

.MultiSelect :global(.uiSelect__clear-indicator) {
  padding-right: 0;
  padding-left: 0;
}

.MultiSelect :global(.uiSelect__menu-list) {
  max-height: 400px;
}

.MultiSelect :global(.uiSelect__multi-value) {
  flex: 1 1 70%;
  width: 0;
}

.MultiSelect.MultiAddQty {
  flex: 1 1 0;
  width: 0;
}
