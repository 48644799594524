.Row {
    display: flex;
    border-bottom: 1px solid #F1F1F1;
    align-items: center;
    padding: 10px 0 10px 30px;
    height: 100%;
    justify-content: flex-start;
}
.Button {
    display: flex;
    align-items: center;
    border: none;
    color: #999999;
    background-color: transparent;
    height: 34px;
    font-family: unset;
    padding: 10px 35px;
}
.IncomeCategoryName {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-wrap: anywhere;
    margin-left: 10px;
    width: 100%;
}
.ExpensesCategoryName {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-wrap: anywhere;
    width: 100%;
}
.Button svg > path {
    stroke: #999999;
}
.Button:hover svg > path {
    stroke: #B23333;
}
.EditLink {
    display: flex;
    align-items: center;
    height: 34px;
    color: #999999;
}
.EditLink:hover svg > path {
    fill: #2D8546;
}
