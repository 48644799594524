.List{
    margin-top: 16px;
}
.NoData{
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
}
