@import url('../../assets/css/bootstrap-grid.css');

@font-face {
  font-family: 'Inter';
  src: url('../../assets/fonts/Inter-Black.woff2') format('woff2'),
    url('../../assets/fonts/Inter-Black.woff') format('woff'),
    url('../../assets/fonts/Inter-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../assets/fonts/Inter-ExtraLight.woff2') format('woff2'),
    url('../../assets/fonts/Inter-ExtraLight.woff') format('woff'),
    url('../../assets/fonts/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../assets/fonts/Inter-Bold.woff2') format('woff2'),
    url('../../assets/fonts/Inter-Bold.woff') format('woff'),
    url('../../assets/fonts/Inter-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../assets/fonts/Inter-Thin.woff2') format('woff2'),
    url('../../assets/fonts/Inter-Thin.woff') format('woff'),
    url('../../assets/fonts/Inter-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../assets/fonts/Inter-Medium.woff2') format('woff2'),
    url('../../assets/fonts/Inter-Medium.woff') format('woff'),
    url('../../assets/fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../assets/fonts/Inter-Light.woff2') format('woff2'),
    url('../../assets/fonts/Inter-Light.woff') format('woff'),
    url('../../assets/fonts/Inter-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../assets/fonts/Inter-Regular.woff2') format('woff2'),
    url('../../assets/fonts/Inter-Regular.woff') format('woff'),
    url('../../assets/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../assets/fonts/Inter-ExtraBold.woff2') format('woff2'),
    url('../../assets/fonts/Inter-ExtraBold.woff') format('woff'),
    url('../../assets/fonts/Inter-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../assets/fonts/Inter-SemiBold.woff2') format('woff2'),
    url('../../assets/fonts/Inter-SemiBold.woff') format('woff'),
    url('../../assets/fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bebas';
  src: url('../../assets/fonts/Bebas-Neue.ttf') format('truetype');
  font-style: auto;
  font-weight: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../../assets/fonts/Raleway-Regular.ttf') format('truetype');
  font-style: auto;
  font-weight: normal;
}