.OfferCardContent {
    position: relative;
    font-size: 14px;
    line-height: 16px;
    color: #202020;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    padding: 24px;
}

.OfferCardContent .SectionHeader {
    margin-top: 70px;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}

.OfferCardContent h4 {
    font-weight: 400;
}

.SectionDivider {
    margin: 35px 0;
    border-bottom: solid thin #ECECEC;
}

.BackLink {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
}
.BackLink:hover {
    font-weight: 700;
}

.Title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px;
}

.TabLinks {
    font-weight: 500;
    /*  */
    font-size: 12px;
}

.TabLinks>li {
    padding: 8px 16px;
    color: #808080;
    cursor: pointer;
}

.TabLinks>li.Active {
    color: #202020;
    border-right: 5px solid #2D8546;
}

.offerTabContent {
    font-weight: 400;
    /*  */
    font-size: 12px;
    line-height: 16px;
    padding-left: 15px;
}

.offerTabContent h3 {
    font-weight: 500;
}

.CommonData .InfoBlock>.Label {
    font-size: 12px;
    line-height: 16px;
    color: #808080;
}

.CommonData .InfoBlock>.Data {
    margin-top: 5px;
}

.Payments .Header {
    padding-bottom: 5px;
}

.Payments .Header>div,
.HistoryItem {
    color: #A7A7A7;
    border-bottom: thin solid #F6F6F6;
    padding-bottom: 10px;
}

.Payment>div {
    border-bottom: thin solid #F6F6F6;
    padding-bottom: 10px;
}

.EditIcon {
    cursor: pointer;
}

.PayAmountBlock {
    position: relative;
    display: inline-block;
}

.newPayAmount {
    display: none;
    position: absolute;
    top: -45px;
    left: -90px;
    width: 220px;
    background: #ffffff;
    box-shadow: 0 4px 18px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    font-size: 12px;
    line-height: 12px;
    padding: 10px 15px;
    z-index: 10;
}

.PayAmountBlock:hover .newPayAmount {
    display: flex;
    justify-content: space-around;
}

.newPayAmount>span:nth-child(2) {
    font-weight: 500;
}

.newValueIcon {
    position: absolute;
    top: -9px;
    right: -10px;
    color: #7230FF;
    font-size: 20px;
}

.historyDate {
    font-size: 90%;
}

.historyDescription p:nth-child(2) {
    color: grey;
}

.HistoryLabel {
    font-weight: 500;
}

.UserName {
    color: #7230FF;
    font-weight: 500;
}

.BorderRight {
    border-right: 1px solid #F6F6F6;
}


.offersCard {
    min-height: 440px;
}