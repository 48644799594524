.container {
    display: flex;
    margin-bottom: 32px;
    border-bottom: 1px solid #ECECEC;
    padding-bottom: 32px;
    align-items: baseline;
}

.title_block {
    width: 440px;
    margin-right: 40px;
}

.title {
    color: #202020;
    font-family: 'Inter';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    word-break: break-all;
}

.link {
    color: #2D8546;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 6px;
}

.link:hover {
    font-weight: 500;
}