.partner_filter_select {
  margin-left: 8px;
  margin-right: 8px;
}



.partners_filter>.selPref__menu {
  width: 100% !important;
  display: none;
}

.partners_filter>.selPref__menu>.selPref__menu-list {
  max-height: 300px !important;
}

.partners_filter>.selPref__menu>.selPref__menu-list>div {
  font-size: 14px !important;
  padding: 8px 12px;
  cursor: pointer;
}

.partner_filter_select>div>.label {
  display: inline-block;
  color: #202020;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 8px;
  bottom: 0;
}

.partners_filter .selPref__single-value {
  font-size: 14px;
  line-height: 14px !important;
}