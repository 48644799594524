.Input {
    margin-top: 20px;
}

.InputBillingRange {
    width: 752px;
    height: 345px;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    padding: 26px 30px;
    box-sizing: border-box;
    flex-shrink: 0;
    margin-top: 5px;
    position: absolute;
    z-index: 2;
}

.bg {
    width: 40%;
    height: 40px;
    position: absolute;
    z-index: 1;
    top: -45px;
    left: 5px;
}

.HeadRight {
    display: flex;
    justify-content: space-between;
}

.Calendar {}

.Left {
    width: 176px;
    border-right: 1px solid #ECECEC;
}

.Right {
    width: 486px;
}

.InputBlock {
    display: flex;

}

.InputBlock input {
    padding: 13px;
    width: 134px;
}

.InputBlock input:first-child {
    margin-right: 5px;
}

.List {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.RangeItem {
    cursor: pointer;
    font-size: 12px;
}

.RangeItem:hover {
    color: #202020;
    font-weight: 500;
    font-size: 12px;
}

.RangeItem.Active {
    color: #202020;
    font-weight: 600;
    font-size: 12px;
}