.label {
    padding: 6px 8px;
    background-color: #EFEFEF;
    color: #000000;
    border-radius: 6px;
    font-size: 14px;
    height: 29px;
    display: flex;
    width: max-content;
    white-space: nowrap;
}