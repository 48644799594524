.Item {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #202020;
}

.Item a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 16px;
    box-sizing: border-box;
    margin-bottom: 8px;
    background: #F6F6F6;
    border-radius: 16px;
    transition: ease-out 0.3s;
}

.Item a:hover {
    background: #ECECEC;
}

.Item a:hover .Icon>svg {
    fill: #2D8546;
}

.Item.Active a {
    background: #2D8546;
}

.Item.Active svg {
    fill: #FFFFFF;
}

.Item.Active a .UserName {
    color: #FFFFFF;
}

.Item .Person {
    width: 300px;
    display: flex;
    align-items: center;
}

.Item .Person .Avatar {
    margin-right: 16px;
    margin-top: 3px;
}

.Item .Role {
    width: 300px;
}

.Item .title {
    color: gray;
    font-weight: 400;
}

.Item .Icon svg {
    fill: #a6a6a6;
    transition: ease-out 0.3s;
}

.List .Icon {
    background: none;
    padding: 0;
    border: none;
}

.Avatar svg {
    fill: #a6a6a6;
}