.wrapper {
    padding: 6px 8px;
    border-radius: 50px;
    border: 1px solid #6FBD85;
    width: fit-content;
    display: flex;
    align-items: center;
    font-size: 14px;
}

.label {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    margin-right: 8px;
    background-color: #6FBD85;
}

.notSentWrapper {
    border: 1px solid #9B9B9B;
}

.notSentLabel {
    background-color: #9B9B9B;
}
