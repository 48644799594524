.selPref__control {
    background: #FFFFFF !important;
    border: 1px solid #383838 !important;
    border-radius: 8px !important;
    padding: 2px 12px;
    /*border: none!important;*/
    /*box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15) !important;*/
}

.selPref__menu {
    background: #FFFFFF !important;
    border-radius: 8px !important;
    margin-top: 8px !important;
    overflow: hidden;
    /* box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1) !important; */
}

.selPref__indicator svg {
    fill: #A7A7A7 !important;
}

.selPref__indicator-separator {
    background: none !important;
}

.selPref__option {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    color: #202020 !important;
}

.selPref__single-value {
    color: #808080 !important;
    font-weight: 400 !important;
    /* font-size: 14px !important; */
    line-height: 16px !important;
}

.selPref__placeholder {
    font-size: 14px;
}

.selPref__control--is-focused {
    /* box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1) !important; */
}

.selPref__option--is-focused {
    background: none !important;
    /* color: #7D40FF !important; */
}

.selPref__option--is-selected {
    background: none !important;
    /* color: #7D40FF !important; */
}

.period .selPref__single-value:before {
    content: '';
    background: url("../../../../../assets/img/calendar.svg") !important;
    width: 12px;
    height: 12px;
    background: red;
    display: block;
    position: absolute;
    right: 0;
    top: 7px;
}


.selPref__menu-list {
    max-height: fit-content !important;
}

.selPref__menu {
    width: 304px !important;
}

.fs-14>.selPref__control {
    font-size: 12px !important;
}

.fs-14>.selPref__menu>.selPref__menu-list>.selPref__option {
    font-size: 12px !important;
}