.btn {
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Bebas', sans-serif;
    height: 38px;
    font-size: 16px;
    background-color: #FFFFFF;
    color: #333333;
    border: 2px solid #333333;
    padding: 10px 26px;
    border-radius: 6px;
    cursor: pointer;
}

.icon {
    margin-right: 14px;
}
