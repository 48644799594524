* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: #202020;
    font-family: Inter, serif;
}

#root {
    width: 100%;
    height: 100vh;
    background-color: #FAFAFA;
}

.wrap {
    width: 1400px;
    display: flex;
    flex-direction: column;
}

a {
    text-decoration: none;
}

ul {
    padding: 0;
    list-style: none;
}

.container {
    max-width: 1480px;
    width: 100%;
}

.wrapper {
    max-width: none;
    padding: 0 116px;
}

@media (max-width: 1660px) {
    .wrapper {
        padding: 0 16px;
    }
}

.billingsContainer .row {
    margin-left: 0;
    margin-right: 0;
    padding-left: 8px;
    padding-right: 8px;
}

.react-datepicker__triangle {
    display: none;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected {
    border: none;
    border-radius: 7px;
    background-color: var(--dark);
    color: var(--white);
}

.react-datepicker__day.react-datepicker__day--keyboard-selected:hover {
    border: none;
    border-radius: 7px;
    background-color: var(--dark);
    color: var(--white);
}

.react-datepicker-popper .react-datepicker__navigation {
    padding-top: 12px;
    color: #000;
}

.react-datepicker {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.189);
    border: none !important;
    font-family: "Inter" !important;
}

.react-datepicker__header {
    border-bottom: solid 5px var(--light) !important;
    background: white !important;
}

.react-datepicker__current-month {
    color: var(--dark) !important;
}

.react-datepicker__day.react-datepicker__day--today {
    border-radius: 7px;
    border: solid 2px var(--brand) !important;
    background-color: white !important;
    color: var(--dark) !important;
    width: 30px;
    height: 30px;
}

.react-datepicker__day.react-datepicker__day--selected {
    border: none;
    border-radius: 7px;
    background-color: black;
    color: white;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
    border: none;
    border-radius: 7px !important;
    background-color: var(--brand) !important;
    color: var(--dark) !important;
}

@media screen and (max-width: 1400px) {
    /* .container {
    max-width: 1150px;
    width: 100%;
    padding: 0;
  } */
}

.billingsContainer .uiSelect__control {
    border: 1px solid #383838;
    border-radius: 8px;
}


.inputBillingRange .react-datepicker__week div:nth-child(6),
.inputBillingRange .react-datepicker__week div:nth-child(7) {
    color: red !important;
}


.selPref__value-container {
    padding-left: 0px !important;
}

.selPref__control {
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.selPref__control:hover {
    border: 1px solid hsl(0, 0%, 70%) !important;
}


.selPref__indicator svg {
    fill: #000 !important;
    margin-left: 4px;
}


::-webkit-scrollbar {
    width: 4px !important;
}

::-webkit-scrollbar-track {
    border-radius: 3px;
    background: #F1F1F1;
}

::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #808080;
}

/*# sourceMappingURL=app.css.map */

.rdw-editor-toolbar {
    border-radius: 3px 3px 0px 0px !important;
    border: 1px solid #A7A7A7 !important;
    background: #F1F1F1 !important;
}

.editorClassName {
    border-radius: 0px 0px 3px 3px !important;
    border: 1px solid #A7A7A7 !important;
    min-height: 126px !important;
    margin-top: -6px !important;
    padding-left: 15px;
    padding-right: 15px;
}

.rdw-option-wrapper,
.rdw-block-wrapper,
.rdw-fontsize-wrapper,
.rdw-dropdown-wrapper {
    background-color: transparent !important;
}

.sectionTextEditor .rdw-image-wrapper {
    display: none;
}

.rdw-fontfamily-wrapper,
.rdw-colorpicker-wrapper,
.rdw-option-disabled,
.rdw-embedded-wrapper,
.rdw-emoji-wrapper,
.rdw-image-wrapper,
.rdw-remove-wrapper,
.rdw-image-modal-size {
    display: none !important;
}

/* knowledge filters */

.selPref__menu {
    z-index: 2 !important;
}

.editorClassName figure {
    /* pointer-events: none; */
    display: flex;
    margin: 0 auto;
    width: 50%;
    position: relative;
}

.editorClassName figure span {
    pointer-events: none;
}

.editorClassName img {
    max-width: 100%;
}

.uiSelect__menu-list,
.uiSelect__multi-value__label,
.uiSelect__placeholder,
.selPref__single-value,
.selPref__placeholder {
    font-size: 12px !important;
}