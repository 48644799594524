.userSetting .UserList_List__q1H_4 {
    margin-top: 0;
    padding-top: 16px;
    border-right: 1px solid #F6F6F6;
    padding-right: 18px;
    min-height: 500px;
}

.userSetting .UserList_List__q1H_4 li {
    margin-bottom: 8px;
}

.userSetting .radioBlock {
    margin-top: 10px;
}

.userSetting .radioBlock .title {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

.userSetting .radioBlock .row {
    margin-left: 0;
}

.userSetting .radioBlock .inputRadio {
    margin-right: 18px;
}

.controlSection {
    border-bottom: 1px solid #F6F6F6;
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.radiosTitle {
    font-size: 14px !important;
}