.Item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    padding: 16px 0 8px;
    border-bottom: 1px dashed #ECECEC;
    box-sizing: border-box;
}

.Item>div {
    font-size: 12px;
}

.AffiseId {
    width: 9%;
    color: #808080;
}

.PartneeId {
    width: 10%;
    color: #808080;
}

.PartneeLogin {
    width: 26%;
}

.Revenue {
    width: 20%;
}

.Status {
    width: 20%;
}

.Date {
    width: 15%;
    color: #808080;
}

@media (max-width: 1285px) {
    .PartneeId {
        width: 16%;
        color: #808080;
    }

    .Revenue {
        width: 28%;
        padding-right: 5px;
    }
}