.Timer .Container{
    display: flex;
    align-items: center;
    margin-left: 10px;
}
.Timer .Container>div{
    font-family: 'Badas', sans-serif;
    font-size: inherit;
    color: white;
    font-weight: inherit;
    font-stretch: inherit;
    width: 22px;
}
.Timer{
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
    font-family: inherit;
    font-stretch: inherit;
}
