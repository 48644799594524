.addIcon {
  fill: #a6a6a6;
}

.icon.arrow svg {
  width: 16px;
  height: 16px;
}

.icon.arrow {
  position: relative;
  top: 2px;
  left: 8px;
}

.icon.notification {
  position: relative;
  top: 4px;
  right: 8px;
}

.searchIcon {
  stroke: #202020;
}

.closeIcon {
  position: relative;
  top: 4px;
  fill: #FF4040;
  margin-right: 8px;
}

.clearIcon {
  position: relative;
  top: 4px;
  fill: #202020;
  margin-left: 89%;
  margin-top: 4px;
  width: 18px;
  padding-right: 5px;
  cursor: pointer;
}

.reloadIcon {
  fill: #2D8546;
  margin-right: 8px;
}