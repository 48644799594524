.app_btn {
    background: #7D40FF;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    font-style: normal;
    color: #FFFFFF;
    padding: 16px 32px;
    margin: 0;
    border-radius: 40px;
    cursor: pointer;

}

.btn {
    background: #7D40FF;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    font-style: normal;
    color: #FFFFFF;
    padding: 8px 16px;
    margin: 0;
    border-radius: 16px;
    border: none;
    cursor: pointer;


}

.icon {
    border: none;
}

.Login {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    padding: 16px 32px;
    border-radius: 40px;
}

.BillingPay {
    background: none;
    color: #7D40FF;
    padding: 0;
}

.Line {
    background: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding: 12px 16px;
    color: #7D40FF;
    border: 1px solid #7D40FF;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: ease-out 0.3s;
}

.Line span {
    background: red;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    display: block;

}

.Line:hover {
    background: #D0D0D0;
    border: 1px solid transparent;
}

.btnB {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding: 12px 16px;
    display: flex;
    margin: 0;
    border-radius: 16px;
    height: 40px;
}

.No {
    background: transparent;
    width: 100%;
    text-align: left;
    color: inherit;
    font-weight: 500;
    /*  */
    font-style: 14px !important;
}

.btnB svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    display: flex;
    align-items: center;
}

.Fiol {
    transition: ease-out 0.3s;
}

.Fiol svg {
    fill: #FFFFFF;
}

.Fiol:hover {
    background: #5518D7;
}

.GreyLight {
    background: #F6F6F6;
    color: #202020;
    transition: ease-out 0.3s;

}

.GreyLight svg {
    fill: #202020;
}

.GreyLight:hover {
    background: #D0D0D0;
}

.Grey {
    background: #808080;
    color: #FFFFFF;
    transition: ease-out 0.3s;
}

.Grey:hover {
    background: #D0D0D0;
}

.Grey svg {
    fill: #FFFFFF;
}

.Green {
    background: #0ECD21;
    color: #FFFFFF;
    transition: ease-out 0.3s;

}

.Green svg {
    fill: #FFFFFF;
}

.Green:hover {
    background: #00B90D;
}

.Red {
    background: #FF4040;
    color: #FFFFFF;
    transition: ease-out 0.3s;
}

.Red:hover {
    background: #EB2C2C;
}

.Red svg {
    fill: #FFFFFF;
    top: 0;
}

.DeleteFill {
    background: #FF4040;
    color: #FFFFFF;
    margin-right: 16px;
}

.DeleteRed {
    background: transparent;
    color: #FF4040;
    padding: 0 !important;
}

.FiolNew {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding: 8px 12px;
    border-radius: 16px;
    background: #5518D7;

}