.wrapperContainer {
    /* padding-top: 15px; */
    padding-bottom: 30px;
    min-height: 360px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 24px;
    height: 100%;
    max-height: 300px;
    background-image: url(../../../assets/img/add-section-icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    border: 1px solid #ECECEC;
}

.container:hover {
    border: 1px solid #2D8546;
}