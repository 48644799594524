.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.label {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;
}

.inputWrapper {
    display: flex;
    width: 100%;
    height: 40px;
    border: 1px solid #333333;
    background-color: #FFFFFF;
    border-radius: 6px;
}

.input {
    height: 38px;
    font-size: 14px;
    line-height: 16px;
    border: none;
    padding: 0 12px;
    border-radius: 6px;
    width: 100%;
}

.input:focus {
    outline: none;
}

.adornment {
    display: flex;
    align-items: center;
}

.errorMessage {
    font-size: 12px;
    line-height: 14px;
    color: #FF0000;
    margin-top: 4px;
}
