.container {}


.addArticle {
    margin-top: 32px;
}

.addSection {
    margin-bottom: 32px;
    display: flex;
}

.addArticleBtn {
    background-color: #fff !important;
    padding: 0px;
    padding-left: 18px;
    padding-right: 22px;
    background-color: transparent;
    height: 36px;
    color: #333;
    font-family: 'Bebas';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 92%;
    border-radius: 6px;
    border: 1px solid #333;
    display: flex;
    align-items: center;
}

.addSectionBtn {
    background-color: #fff !important;
    padding: 0px;
    padding-left: 18px;
    padding-right: 22px;
    background-color: transparent;
    height: 36px;
    color: #333;
    font-family: 'Bebas';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 92%;
    border-radius: 6px;
    border: 1px solid #333;
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.addSectionBtn:hover,
.addArticleBtn:hover {
    background-color: transparent !important;
}

.plusBtn {
    margin-right: 6px;
}