.container {
    width: 100%;
    height: 216px;
    background-color: #AFF1B7;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 22px;
}

.title {
    color: #222;
    text-align: center;
    font-family: 'Inter';
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 24px;
}