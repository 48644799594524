.List {
    margin-top: 16px;
}

.NoData {
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
}

.Content {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    flex-wrap: wrap;
}

.Content>div {}

.Title {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #A7A7A7;
    margin-top: 26px;
    margin-bottom: 24px;
}

.KZTBank .Content>div {
    width: 100%;
}

.CurrencyBlock {

    border-bottom: 1px solid #ECECEC;
    box-sizing: border-box;
}

.CurrencyBlock:last-child {
    border-bottom: none;
}

.CurrencyBlock:last-child .Content>div:last-child {

    margin-bottom: 0;
}