.TabsContainer {
    display: flex;
    justify-content: space-between;
}

.LeftBlock {
    /* width: 30%; */
    width: 12%;
    border-right: 1px solid #F6F6F6;
    min-height: 460px;
}

.RightBlock {
    width: 100%;
    padding-left: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    /* height: 445px; */
    padding-right: 10px;
    position: relative;
    padding-top: 8px;
}

.Control {
    color: #808080;
    /*  */
    font-size: 12px !important;
}

.Control:hover {
    color: #2D8546;
}

.Control.Active {
    color: #202020;
    border-right: 5px solid #2D8546;
    border-radius: 0;
}

.Content {
    display: none;

}

.Content.Active {
    display: block;
}

.contentLoader {
    width: 98%;
    height: 100%;
    min-height: 388px;
    z-index: 99;
    position: absolute;
    top: 0;
}

.switchBlock {
    display: flex;
    margin-top: 25px;
}

.switchText {
    color: #808080;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    margin-right: 14px;
}

.switchInput[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
}

.switchLabel {
    cursor: pointer;
    text-indent: -9999px;
    width: 40px;
    height: 20px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
}

.switchLabel:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

.switchInput:checked+.switchLabel {
    background: #2D8546;
}

.switchInput:checked+.switchLabel:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.switchLabel:active:after {
    width: 20px;
}