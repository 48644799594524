.Item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-height: 24px;
    padding-top: 16px;
    border-bottom: 1px solid #F6F6F6;
    padding-bottom: 8px;
    box-sizing: border-box;
}
.Item div{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

.Item .Number{
    width: 30%;
}

.Item .Title{
    width: 30%;
    font-size: 14px;
}
.Item .Status{
    width: 30%;
    font-size: 14px;
}
.Item .Btn{
    display: flex;
    align-items: flex-end;
}
.Item .Btn button:first-child{
     margin-right: 0;
}