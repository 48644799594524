.ListHeader {
    padding: 15px;
    border-bottom: 1px solid #ECECEC;
    background: #2D8546;
    border-radius: 8px;
    position: relative;
    margin: 0px !important;
    padding-right: 0px;
}

.ListHeader>div {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    margin: 0;
    display: flex;

}

.ListHeader>div>span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    margin: 0;
    display: flex;

}

.Billings_Content {
    margin-top: 40px;
    min-height: 600px;
}

.Name {
    width: 17.2%;

}

.Id {
    width: 3.8%;
}

.Manager {
    width: 14%;

}

.Payout {
    width: 13%;

}

.Debt {
    width: 13%;
}

.Requisites {
    width: 20%;

}

.Status {
    width: 7%;
}

.Additionally {
    width: 7%;
    padding-left: 15px;
}

.Btn {
    width: 3%;
}

.Down {
    background: url("../../../../assets/img/icon-dashed-piramid.svg") no-repeat center center;
    width: 16px;
    height: 16px;
    transform: rotate(0deg);
    margin-left: 8px;
}

.Up {
    background: url("../../../../assets/img/icon-dashed-piramid.svg") no-repeat center center;
    transform: rotate(180deg);
    width: 16px;
    height: 16px;
    margin-left: 8px;
}

.Icon {
    cursor: pointer;
}

.NoData {
    margin-top: 80px;
    text-align: center;
}

.NoData h1 {
    font-size: 20px;
}

.ListBody {
    /* padding: 0px 15px; */
}

.result_header {
    border-radius: 8px;
    background: #E6E6E6;
    padding: 15px;
    border-bottom: 1px solid #ECECEC;
    border-radius: 8px;
    position: relative;
    margin: 6px 0px !important;
    padding-right: 0px;
}

.result_header>div {
    color: #000;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.result_header_text {
    color: #808080;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.Active {
    display: flex;
    align-items: flex-end;
}

.Active .Down,
.Active .Up {
    background: url("../../../../assets/img/icon-dashed-piramid.svg") no-repeat center center;
    background-size: contain;
    width: 16px;
    height: 16px;
    margin-left: 8px;
}

.Active .Up {
    transform: rotate(180deg);
}

.debt_text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    margin: 0;
    display: flex;
}