.TitleBlock {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    padding: 0 !important;
}
.NavLink {
    font-family: 'Bebas', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    padding: 6px 20px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    background-color: #ffffff;
    margin-left: 14px;
}
.NavLink > span {
    color: #2D8546;
    font-size: 20px;
    margin-right: 10px;
}
.SearchParamsBlock {
    display: flex;
}
.Label {
    display: block;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;
    padding-left: 5px;
}
.FilterItem {
    width: 330px;
    padding-right: 12px;
}
.FilterItem :global(.uiSelect__control),
.FilterItem :global(.uiSelect__menu) {
    font-size: 12px;
}
.FilterItem :global(.uiSelect__control) {
    border: 1px solid #383838;
    border-radius: 8px;
}
.FilterItem :global(.uiSelect__option) {
    padding-top: 7px;
    padding-bottom: 7px;
}
.FilterItem :global(.uiSelect__option input::before) {
    width: 18px;
    height: 18px;
    border-radius: 3px;
}
.FilterItem :global(.uiSelect__option input::after) {
    width: 14px;
    height: 14px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.GetReportBtn {
    margin-top: 24px;
}
.ProjectsContent {}
.Input {
    border: 1px solid #383838;
    border-radius: 8px;
    height: 38px;
    width: 100%;
    padding: 2px 12px;
}
.Input:hover {
    border: 1px solid hsl(0, 0%, 70%);
}
.Input:focus, .Input:active {
    box-shadow: none;
    outline: none;
}

