.container {
    margin-top: 57px;
    margin-bottom: 24px;
    min-height: 320px;
    position: relative;
}

.title {
    color: #202020;
    text-align: center;
    font-family: 'Inter';
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}

.items {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    /* margin-top: 24px; */
    /* gap: 10px; */
    /* padding-top: 24px; */
    padding-bottom: 24px;
    justify-content: center;
}