.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.wrapper p {
    text-align: center;
}

.button {
    margin-top: 20px;
}