.container {
    padding-left: 36px;
    width: 800px;
    position: relative;
}

.titleAndIcon {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    color: #202020;
    font-family: 'Inter';
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}

.description {
    color: #202020;
    font-family: 'Inter';
    /* font-size: 16px; */
    /* font-style: normal; */
    /* font-weight: 400; */
    /* line-height: 24px; */
    margin-bottom: 24px;
    margin-top: 24px;
    word-break: break-word;
}

.description a * {
    color: #2D8546 !important;
}

.description a:hover {
    font-weight: 500;
}

.description span {
    background-color: transparent !important;
    word-break: break-word !important;
    font-family: 'Inter' !important;

}

.description p {
    background-color: transparent !important;
    word-break: break-word !important;
    font-family: 'Inter' !important;

}

.description p {
    display: block !important;
    font-family: 'Inter' !important;
    margin-block-start: 0.5em !important;
    margin-block-end: 0.5em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
    font-family: 'Inter' !important;

}

.description h1,
.description h2,
.description h3,
.description h4,
.description h5,
.description h6 {
    display: block;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    word-break: break-word !important;
    font-family: 'Inter' !important;
}

.description img {
    max-width: 100%;
    width: 100% !important;
    border-radius: 16px !important;
    margin: 40px 0px;
}

.description ul {
    font-family: 'Inter' !important;
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    word-break: break-word;
}

.description ol {
    font-family: 'Inter' !important;
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    word-break: break-word;
}

.description pre {
    background-color: #f0f0f0 !important;
    /* Example background color */
    padding: 10px !important;
    border: 1px solid #ccc !important;
    overflow-x: auto !important;
}

.description pre::-webkit-scrollbar {
    height: 4px;
}

.description pre::-webkit-scrollbar-thumb {
    border-radius: 3px !important;
    background: #808080 !important;
}

.description pre::-webkit-scrollbar-track {
    border-radius: 3px !important;
    background: #F1F1F1 !important;
}

.firstText {
    color: #202020;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 24px;
}

.secondText {
    color: #202020;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 24px;
}

.secondTitle {
    color: #202020;
    font-family: 'Inter';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 24px;
}

.thirdTitle {
    color: #202020;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    margin-bottom: 24px;
}

.list {
    list-style-type: disc;
    padding-left: 15px;
}

.imageZone {
    margin: 48px 0px;
    width: 100%;
    height: 463px;
    border-radius: 16px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.removeIcon {
    width: 22px;
    height: 22px;
    margin-left: 10px;
    cursor: pointer;
}

.img {
    margin: 48px 0px;
    width: 100%;
    height: auto;
    border-radius: 16px;
}

.editLink {
    display: flex;
}

.editIcon {
    width: 22px;
    height: 22px;
}