.fpl {
    margin-left: 0px !important;
}

.fpr {
    margin-right: 0px !important;
}

.sections_filter>.selPref__menu {
    width: 100% !important;
}

.sections_filter>.selPref__menu>.selPref__menu-list {
    max-height: 300px !important;
}

.sections_filter>.selPref__menu>.selPref__menu-list>div {
    font-size: 14px !important;
    padding: 8px 12px;
    cursor: pointer;
}

.sections_filter .selPref__single-value {
    font-size: 14px;
    line-height: 14px !important;
}


.sections_filter_select>div>div>.label {
    display: inline-block !important;
    color: #202020 !important;
    font-family: 'Inter' !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    margin-bottom: 8px !important;
    bottom: 0 !important;
}

.articleEditor {
    min-height: 320px !important;
    overflow-y: auto;
}

.fpl .inputField:hover {
    border: 1px solid hsl(0, 0%, 70%) !important;
}