.FilterItem {
    width: 330px;
    padding-right: 12px;
}

.Label {
    display: block;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 8px;
    padding-left: 5px;
    font-weight: 600;
}

.DatePicker {
    border-radius: 8px;
    border: 1px solid #383838;
    background: #FFF;
    height: 38px;
}

.SearchParamsBlock {
    display: flex;
    justify-content: flex-start;
    margin: 10px 50px 0 50px;
    height: 80px;
}


.CurrencyDropDown {
    display: flex;
    align-items: flex-end;
    height: 38px;
}

.CurrencySelect {
    width: 350px;
    margin-right: 12px;
    margin-bottom: -2px;
}

.CurrencyRateRow {
    display: flex;
    justify-content: space-around;
    margin: 10px 50px 0 50px;
    font-weight: 600;
}