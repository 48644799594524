.container {
    margin-top: 58px;
}

.title {
    color: #222;
    font-family: 'Inter';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 14px;
}

.inputTitle {
    color: #202020;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 8px;
    display: flex;
}

.input_block {
    width: 100%;
}

.partner_filter_select>div>.label {
    display: inline-block;
    color: #202020;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 8px;
    bottom: 0;
}

.filters_content {
    display: flex;
    align-items: center;
}

.filters {
    width: 33.3%;
    margin-left: 6px;
    margin-right: 6px;
}

.article_editor {
    margin-top: 14px;
}

.fileInputCol {
    display: flex;
    align-items: flex-end;
    width: 100%;
}

.sectionFileInputBlock {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.file_input_label {
    background-color: #fff;
    position: relative;
    display: flex;
    height: 38px;
    padding: 11px 22px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: 1px solid #333;
    margin-right: 14px;
}

.file_input_label:hover {
    background-color: transparent;
}

.file_input_label span {
    color: #333;
    font-family: 'Bebas';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 92%;
    min-width: 87px;
}

.fileName {
    color: #C4C4C4;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    overflow: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: fit-content;
}

.fileInput {
    position: absolute;
    top: 10%;
    left: 0px;
    z-index: -10;
    opacity: 0;
    cursor: pointer;
}

.createArticle {
    padding: 0px 22px;
    background-color: #383838;
    height: 36px;
    color: #FFF;
    font-family: 'Bebas';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 92%;
    border-radius: 6px;
}

.createArticle:hover {
    background-color: #171717 !important;
}

.viewBtn {
    background-color: #fff !important;
    padding: 0px;
    padding: 0px 22px;
    background-color: transparent;
    height: 36px;
    color: #333;
    font-family: 'Bebas';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 92%;
    border-radius: 6px;
    border: 1px solid #333;
    margin-right: 8px;
}

.viewBtn:hover {
    background-color: transparent !important;
}

.btnContainer {
    margin-top: 14px;
}

.btns {
    display: flex;
    justify-content: flex-end;
}

.fileNameColor {
    color: #2D8546;
}

.removeIcon {
    width: 24px;
    height: 24px;
    background-image: url(../../../../../../assets/img/article-remove-icon.png);
    background-repeat: no-repeat;
    margin-left: 8px;
    cursor: pointer;
}

.removeIcon:hover {
    width: 24px;
    height: 24px;
    background-image: url(../../../../../../assets/img/article-remove-hover-icon.png);
    background-repeat: no-repeat;
    margin-left: 8px;
    cursor: pointer;
}

.removeZone {
    display: flex;
    align-items: center;
}