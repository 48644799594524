.CurrencyRow {
    display: flex;
}
.SwitchBlock {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.TableCell {
    padding: 10px;
}
.Name {
    /* padding-top: 20px; */
    /* padding-bottom: 5px; */
    margin-top: 20px;
    margin-left: -200px;
    margin-bottom: -20px;
    width: 20px;
}
.Names {
    /* display: flex; */
}
