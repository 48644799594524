.Head {
    display: flex;
    justify-content: space-between;
}

.BtnBlock {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 70%;
}

.BtnBlock button {
    margin-left: 8px;
    display: flex;
    align-items: center;
}

.BtnBlock svg {
    margin-bottom: 3px;
}

.BtnRed svg>path {
    fill: #B23333;
}

.BtnGreen {
    color: #2D8546;
    border: 2px solid #2D8546;
    background: #FFFFFF;
}

.BtnGreen svg>path {
    fill: #2D8546;
}

.BtnGray svg>path {
    fill: #999999;
}

.Content {
    margin-top: 32px;
    position: relative;
    min-height: 300px;
}

.Th {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #808080;
    height: 57px;
    border-bottom: 1px solid #ECECEC;
    flex-shrink: 0;
}

.Th>div {
    margin-top: -4px;
    margin-left: 8px;
}

.ContentHeader {
    display: flex;
    align-items: flex-start;
}

.Th.Checkbox {
    width: 2.8%;
}

.Th.Num {
    width: 2.6%;
}

.Th.Id {
    width: 11%;
}

.Th.Offer {
    width: 15.5%;
}

.Th.ClickId {
    width: 13.2%;
}

.Th.OrderId {
    width: 18.2%;
}

.Th.Revenue {
    width: 6.7%;
    padding-left: 8px;
}

.Th.Payments {
    width: 6.7%;
}

.Th.Status {
    width: 11.8%;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
}

.Th.Date {
    width: 10.5%;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
}

.Th.Btn {}

.Th.Active {
    color: #7D40FF;
}

.NoData {
    margin-top: 80px;
    text-align: center;
}

.NoData h1 {
    font-size: 20px;
}

.Desc {
    margin-top: 8px;
    color: #FF4040;
    font-size: 14px;
}

.Head .Title {
    width: 30%;
}

.Th.Active .Down {
    background: url("../../../../../assets/img/arrow.svg");
    width: 20px;
    height: 20px;
    margin-left: 0px;
    margin-top: 0px;
}

.Th.Active .Up {
    background: url("../../../../../assets/img/arrow.svg");
    transform: rotate(180deg);
    width: 20px;
    height: 20px;
    margin-left: 0px;
    margin-top: -2px;
}

.White {
    color: #FFFFFF;
}