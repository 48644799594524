.BackLink {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
}

.BackLink:hover {
    font-weight: 700;
}

.Label {
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    color: #808080;
}

.LabelCapitalist {
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    color: #808080;
    margin-left: 200px;
}

.GeneralInfo .Label {
    margin-bottom: 7px;
}

.Red {
    color: #B23333;
}

.Blue {
    color: blue;
}

.Gray {
    color: #808080;
}

.Small {
    font-size: 12px;
}

.BorderRight {
    border-right: 1px solid #F6F6F6;
}

.BorderBottom {
    border-bottom: 1px dashed #CCCCCC;
}

.BillingContent {
    position: relative;
    font-size: 14px;
    line-height: 16px;
    color: #202020;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    padding: 24px;
    min-height: 500px;
    overflow-x: hidden;
}

.BillingContent h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
}

.Header {
    display: flex;
    justify-content: space-between;
    padding: 0 30px 0 8px;
}
.Header :global(.label) {
    display: none;
}
.Header :global(.selPref__control) {
    width: 150px;
    border-color: #383838 !important;
}
.Header :global(.selPref__value-container) {
    width: 150px;
    padding-right: 0;
}
.Header :global(.selPref__menu) {
    width: 150px !important;
}
.Header :global(.selPref__control),
.Header :global(.selPref__control:focus),
.Header :global(.selPref__control:active) {
    box-shadow: none !important;
    outline: none !important;
}
.Title {
    display: flex;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
}

.TabLinks {
    font-weight: 500;
    /*  */
    font-size: 12px;
}

.TabLinks>li {
    margin-bottom: 12px;
    padding: 7px 16px;
    color: #808080;
    cursor: pointer;
}

.TabLinks>li.Active {
    color: #202020;
    border-right: 5px solid #2D8546;
}

.TransactionId {
    margin-left: 15px;
}

.TransactionId span {
    color: #808080;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.Amount {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
}
.RefAmount > div {
    font-size: 10px;
}
.RefAmount > div:first-child {
    color: #808080;
}
/******************** Tabs *******************/
.TabContainer,
.TabContainer {
    margin-top: 45px;
}

.SecondRow {
    margin-top: 90px;
}

.PayAgent,
.PaySystem {
    display: flex;
    align-items: center;
    height: 39px;
    font-size: 12px;
}

.PayAgent {
    border: 1px solid #383838;
    border-radius: 8px;
    padding-left: 25px;
    color: #808080;
    font-size: 12px;
}

.Email {
    font-size: 12px;
}

.ResultPaymentParams {
    display: inline-block;
    padding: 7px 15px;
    border-top: 1px solid #F6F6F6;
    border-bottom: 1px solid #F6F6F6;
    border-radius: 8px;
    background: #EFE7FF;
}

.ResultPaymentAmount {
    font-weight: 500;
}

.ResultPaymentCalc {
    margin-top: 5px;
    font-size: 80%;
}

.InfoBlock {
    border-bottom: 1px dashed #CCCCCC;
    padding: 15px 0;
    line-height: 20px;
    font-size: 12px;
}

.InfoBlock label {
    margin-bottom: 0;
    line-height: 20px;
}

.PaySystemBlock {
    padding: 6px 0;
}

.NdsRadio {
    display: flex;
    justify-content: space-between;
}

.NdsRadio>div {
    display: flex;
    align-items: center;
    margin-top: 0;
}

.NdsRadio>div:first-child {
    margin-left: 0;
}

.LoadFileBtn {
    align-items: center !important;
    justify-content: center;
    border: 1px solid #333333;
    border-radius: 6px !important;
    margin-right: 0 !important;
    padding: 7px 12px 6px !important;
    line-height: 16px;
    background: #FFFFFF !important;
    cursor: pointer;
}

.LoadFileBtn label {
    color: #333333 !important;
    font-family: 'Bebas', sans-serif !important;
    font-size: 16px;
}

.LoadFileBtn svg {
    top: 2px !important;
}

.LoadFileBtn svg>path {
    fill: #333333 !important;
}

.Hash {
    white-space: pre-wrap;
    font-size: 12px;
}

.DocLabel {
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
    line-height: 16px;
    color: #808080;
}

.DocLabel small {
    color: #808080;
}

.DocLink {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Bebas', sans-serif;
    font-size: 16px;
    line-height: 18px;
    color: #333333;
    background: #FFFFFF;
    border: 1px solid #333333;
    border-radius: 6px;
    padding: 9px 12px 7px;
}

.DocBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Bebas', sans-serif;
    font-size: 16px;
    line-height: 18px;
    color: #333333;
    background: #FFFFFF;
    border: 1px solid #333333;
    border-radius: 6px;
    padding: 9px 12px 7px;
    cursor: pointer;
}

.BtnDelete {
    background: #ffffff;
    border: none;
    margin-right: 9px;
    cursor: pointer;
}

.NdsAmount {
    color: #808080;
}

.ErrorLabel {
    font-size: 12px;
    line-height: 16px;
    border-radius: 10px;
    padding: 1px 5px;
    color: red;
    border: 1px solid red;
}

.DropDownBlock {
    cursor: pointer;
}

.InvoiceBlock {}

.InvoiceBlock h3 {
    color: #808080;
    font-weight: 400;
}

.PeriodInput {
    border: 1px solid #383838;
    border-radius: 8px;
    padding: 0 10px;
    color: #808080;
    width: 150px;
    height: 38px;
    margin: 0 5px;
}

.PeriodInput:active,
.PeriodInput:focus {
    outline: none;
    box-shadow: none;
}

.contentLoader {
    width: 98%;
    height: 100%;
    min-height: 388px;
    z-index: 99;
    position: absolute;
    top: 0;
}

.add_comment {
    /* margin-bottom: 10px; */
    margin-left: 24px;
    margin-top: 6px;
}

.add_comment_text {
    color: #2D8546;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer;
    /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
    /* width: 100%; */
    max-width: 100%;
    word-wrap: break-word;
    min-width: fit-content;
    color: #2D8546;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    border-bottom: 1px solid #2D8546;
}

.add_icon {
    width: 28px;
    height: 28px;
    margin-left: 8px;
    cursor: pointer;
}

.add_comment_content {
    display: flex;
    width: 284px;
}

.add_comment_content button {
    margin-left: 15px;
}

.comment_content {
    display: flex;
    align-items: center;
}

.comment_content img {
    cursor: pointer;
    margin-left: 4px;
}

.StatusTooltip>div {
    right: unset;
    left: -26px;
}

.add_comment_text_show {
    background-color: #FFF4F4;
    padding: 8px 16px;
    color: #B23333;
    border-radius: 5px;
}

.conTitle {
    font-size: 12px;
}

.tooltip_button {
    padding: 8px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.15);
    position: absolute;
    min-width: 134px;
    color: #B23333;
    font-family: 'Inter';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    box-sizing: border-box;
    top: 45px;
    word-break: break-word;
    text-align: center;
    z-index: 1;
    transform: translate(-50%);
    left: 50%;
}

.tooltip_file_button {
    padding: 8px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.15);
    position: absolute;
    min-width: 134px;
    color: #B23333;
    font-family: 'Inter';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    box-sizing: border-box;
    word-break: break-word;
    text-align: center;
    z-index: 1;
    right: 0;
    top: -40px;
}


