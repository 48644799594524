.Title {
    display: flex;
    align-items: center;
    height: 38px;
}
.Title > h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
}
.Divider {
    margin: 0 25px;
    /*font-size: 32px;*/
    height: 100%;
    width: 1px;
    background-color: #D9D9D9;
}
.PartnerData {
    font-size: 14px;
    line-height: 16px;
    margin-right: 25px;
}
.ID {
    color: #808080;
}
.NavLink {
    font-family: 'Bebas', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    padding: 6px 20px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    background-color: #ffffff;
    margin-left: 14px;
}
.NavLink > span {
    color: #2D8546;
    font-size: 20px;
    margin-right: 10px;
}
.ContentBlock, .Filter {
    margin-top: 25px;
}
.ContentBlock * {
    font-size: 13px;
    line-height: 92%;
}
.Label {
    display: block;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;
}
.DatePicker {
    border-radius: 8px;
    border: 1px solid #383838;
    background: #FFF;
    height: 38px;
}
.Filter :global(.react-datepicker__month-text.react-datepicker__month--selected),
.Filter :global(.react-datepicker__month-text.react-datepicker__month--in-range),
.Filter :global(.react-datepicker__month-text.react-datepicker__month--selected:hover),
.Filter :global(.react-datepicker__month-text.react-datepicker__month--in-range:hover) {
    color: #FFFFFF !important;
    background-color: #2D8546 !important;
}
.Filter :global(.react-datepicker__month-text.react-datepicker__month-text--keyboard-selected) {
    color: #202020;
    background-color: unset;
}
.ContentHeader {
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    background-color: #2D8546;
    padding: 17px;
}
.ContentHeader * {
    color: #FFFFFF;
}
