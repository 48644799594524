.ListHeader {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
}

.ListHeader div {
    color: #808080;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}
.Num{
    width: 12%;
}
.Num span{
    font-size: 10px;
}
.Sum{
    width: 24%;
}
.Debit{
    width: 30%;
}

.Date{
    width: 20%;
}
.NoData{
    margin-top: 30px;
    text-align: center;
}
.NoData h1{
    font-size: 14px;
}