.container {
  display: flex;
  align-items: flex-end;
}

.input_block {
  width: 300px;
  margin-right: 8px;
}

.Label {
  display: inline-block;
  color: #202020;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 8px;
}

.BtnBlock {
  margin-top: 27px;
  height: 38px;
}

.BtnBlock button {
  width: 86px;
  padding: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: -0.55px; */
  border: 1px solid #383838;
}

.BtnBlock button:hover {
  background-color: #171717;
  border: none;
}

.selPref__option {
  font-size: 14px !important;
}