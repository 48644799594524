.Item {
    /* padding-top: 12px; */
    border-bottom: 1px solid #ECECEC;
    /* padding-bottom: 12px; */
    cursor: pointer;
    display: flex;
    /* justify-content: space-between; */
    margin: 0px;
    padding: 4px 16px;
    padding-right: 0px;

}

.Item:hover {
    background: #ECECEC;
}

.Dash {
    color: #999999;
    width: 13%;
    padding-left: 5px;
}

.Item>div {
    box-sizing: border-box;
    font-size: 12px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.Col {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #202020;
    margin: 0;
}

.Name {
    width: 17.2%;
}

.Id {
    width: 3.8%;

}

.Manager {
    width: 14%;
}

.Payout {
    width: 13%;
}

.Debt {
    width: 13%;
}

.Requisites {
    width: 19%;

}

.Status {
    width: 4%;
    padding-left: 20px;
}

.Additionally {
    width: 8%;
    padding-left: 70px;
}

.Status {
    width: 4%;
}

.Btn {
    width: 7%;
    display: flex;
    justify-content: flex-end;
}

.Date {
    font-size: 12px;
    color: #808080;
}

.Debt .Sum {
    color: #FF4040;
}

.Debt .Sum.Active {
    color: #0ECD21;
}

.HiddenContainer {
    display: none;
    margin-top: 10px;
    width: 100%;
    margin-bottom: 11px;
}

.HiddenContainer ul {
    display: flex;
    padding-left: 32px;
}

.HiddenContainer.Active {
    display: block;
}

.HiddenTitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #808080;
}

.HiddenValue {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #202020;

}

.Alert {
    font-size: 12px;
    color: #FF4040;
    margin-left: 10px;

}

.Td svg,
.Td span {
    fill: #a6a6a6;
}

.Td .ActiveHidden span {
    margin-top: -4px;
    left: 0;
    cursor: pointer;
}

.Td .ActiveHidden.Active svg {
    transform: rotate(180deg);
    fill: #202020;
}

.Td .ActiveHidden {
    margin-left: 0;
}

.Td .Settings {
    margin-top: 0;
}

.Td.Btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Settings {
    cursor: pointer;
    transition: ease-out 0.3s;
}

.Settings svg {
    transition: ease-out 0.3s;
}

.Settings:hover svg {
    fill: #202020;
}

.Td>div {
    margin-top: -4px;
    margin-left: 8px;
}

.archive {
    width: fit-content;
    padding: 2px 6px;
    border-radius: 10px;
    background: #E8E8E8;
    color: #808080;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
}

.tooltip {
    padding: 8px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.15);
    position: absolute;
    min-width: 134px;
    color: #B23333;
    font-family: 'Inter';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    box-sizing: border-box;
    left: 16px;
    word-break: break-word;
    text-align: center;
}