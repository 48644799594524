.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.wrapper p {
    text-align: center;
}

.buttons {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}