.inputField {
    background: #F6F6F6;
    border-radius: 8px;
    width: 100%;
    height: 40px;
    border: none;
    box-sizing: border-box;
    padding: 8px 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #202020;
}

.inputField:focus-visible {
    border: none;
    outline: none;
}

.input::-webkit-calendar-picker-indicator {
    display: none;
}

.inputMasked .react-datepicker__tab-loop {
    display: none !important;
}