.Period {}

.Button button {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  padding: 16px 30px;
}

.Button {
  text-align: right;
  padding-right: 0;
}

.Filters {
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0px !important;
}

.Filters>div {
  padding-left: 7px;
  padding-right: 7px;
}

.BtnBlock {
  text-align: left;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 9.9% !important;
  margin-bottom: 14px;
}

.BtnBlock button {
  width: 124px;
  margin-right: 6px;
}

.BtnBlock button:hover {
  background-color: #171717;
  border: none;
}

.PayAccount,
.Status,
.PaySystem {
  width: 190px;
}

.Label {
  display: inline-block;
  color: #202020;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 8px;
}

.filterIcon {
  cursor: pointer;
}

.pd {
  padding: 0px !important;
}

.filterWidth {
  /* max-width: 15% !important; */
  padding-left: 0px;
  margin-bottom: 14px;
}

@media screen and (max-width: 1400px) {
  .BtnBlock {
    margin-top: 20px;
    text-align: left;
    /* margin-left: 16px; */
  }

  .Period {
    /* max-width: 200px; */

  }

  .PayAccount,
  .Status,
  .PaySystem {
    width: 190px;
    /* max-width: 210px; */
    flex: auto;
  }

  .BtnBlock {
    max-width: 100% !important;
    width: 100% !important;
    display: flex;
    flex-direction: column-reverse;
  }

  .BtnBlock button {
    width: 100% !important;
    margin-top: 18px;
  }

}