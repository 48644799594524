.searchBlock {
    position: relative;
    width: 340px;
    border: 1px solid #383838;
    transition: all 0.3s ease-in-out;
}

.searchIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
}

.searchBlock input {
    height: 36px;
    padding: 0 25px 0 45px;
    background: #F6F6F6;
    border-radius: 16px;
    height: 48px;
}

.searchBlock svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
}

.searchContainer label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: block;
    margin-bottom: 8px;
    position: relative;
}

.searchData {
    width: 100%;
    height: 200px;
    background-color: #F6F6F6;
    height: fit-content;
    /* position: absolute; */
    /* top: 300px; */
    padding: 24px;
    padding-right: 0px;
    z-index: -10;
    opacity: 0;
}

.searchBgPassive {
    z-index: -10;
    opacity: 0;
    /* animation: hide;
    animation-fill-mode: forwards;
    animation-duration: 0.5s; */
    /* animation: backOutDown 0.5s; */
}

.searchBgActive {
    z-index: 2;
    animation: show;
    animation-duration: 0.5s;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
}

@keyframes show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes hide {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes backOutDown {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

    20% {
        -webkit-transform: translateY(0px) scale(0.7);
        transform: translateY(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: translateY(700px) scale(0.7);
        transform: translateY(700px) scale(0.7);
        opacity: 0.7;
    }
}

@keyframes backInUp {
    0% {
        transform: translateY(1200px) scale(0.7);
        opacity: 0.7;
    }

    80% {
        transform: translateY(0px) scale(0.7);
        opacity: 0.7;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.backInUp {
    animation-name: backInUp;
    transform: translateY(1200px) scale(0.7);
}