.Filters{
    margin-top: 24px;
    display: flex;
}
.Left{
    width: 50%;
    margin-right: 40px;
}
.Left h3{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}
.Left textarea{
    background: none;
    padding: 24px;
    border: 2px solid #D0D0D0;
    border-radius: 16px;
    height: 200px;
    margin-top: 24px;
    align-items: flex-start;
    width: 100%;
    resize: none;
}
.CheckboxBlock{
    display: flex;
    margin-top: 8px;
}
.CheckboxBlock > div{
    margin-left: 0;
    margin-right: 16px;
}
.BtnBlock{
    margin-top: 16px;
    display: flex;
    align-items: center;
}
.BtnBlock button{
    margin-right: 16px;
}
.Right{
 margin-top: 100px;
}
.SearchResultTitle{
    font-size: 14px;
    line-height: 16px;
    color: #808080;
}
.SearchResultContent{

}
.SearchResultContent p{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-top: 20px;
    margin-bottom: 10px;
}
.Result span{
    color: #FF4040;
    font-size: 14px;
    line-height: 16px
}
