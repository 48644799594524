.Notification {
    position: relative;
    width: 400px;
    border-radius: 5px;
    height: 84px;
    display: flex;
    align-items: center;
    color: #fff;
    margin-bottom: 10px;
    opacity: 0;
    transition: opacity .3s, transform .3s, height 0.5s, margin-bottom 0.3s;
    transform: translateY(-100px);
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
}
.Notification.Primary {
    background: linear-gradient(91.04deg, rgba(125, 64, 255, 0.9) 32.81%, rgba(125, 64, 255, 1) 100%);

}
.Notification.Error {
    background: #FF4040;
}

.Notification.Important {
    z-index: 1500;
}

/**Animation**/
.NotificationActive {
    transform: translateY(0px);
    opacity: 1;
    transition: opacity .3s, transform .3s, height 0.5s, margin-bottom 0.3s;
}
.NotificationDone {
    transform: translateY(0px);
    opacity: 1;
}
.NotificationExitActive {
    transform: translateY(0px);
    opacity: 1;
}
.NotificationExitDone {
    transform: translateY(-100px);
    opacity: 0;
    transition: opacity .3s, transform .3s, height 0.5s, margin-bottom 0.3s;
}
/**End Animation**/


.Notification .NotificationContent {
    font-size: 16px;
    padding: 17px 30px;
    width: 100%;
    color:#FFFFFF;
}
.Notification .NotificationClose {
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #ffffff;
    font-size: 20px;
}

@media (max-width: 768px) {
    .Notification {
        width: 100%;
    }
    .Notification .NotificationClose {
        display: none;
    }
}
