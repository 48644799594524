.Item {
    padding-top: 4px;
    border-bottom: 1px solid #ECECEC;
    padding-bottom: 4px;
    padding-left: 15px;
    cursor: pointer;
}

.Item:hover {
    background: #ECECEC;
}

.Item>div {
    box-sizing: border-box;
}

.Dash {
    color: #999999;
    padding-left: 5px;
}

.Col {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #202020;
    margin: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.Num {
    width: 5%;
}

.Partner, .Manager {
    width: 14.5%;
}

.Sum {
    width: 8%;
    padding-left: 15px;
}

.ConvertSum {
    width: 8%;
    padding-left: 25px;
}

.Type {
    width: 6.5%;

}

.Status {
    width: 7.5%;
    word-break:normal;
    text-align: center;

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #202020;
    margin: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.Status .Alert {
    margin-top: 4px;
    margin-bottom: 8px;
    margin-right: 10px;
}

.Date {
    width: 6%;
    padding-left: 15px;
}

.Item .Type .Alert {
    font-size: 12px;
    margin-left: 0;
}

.PaySystem {
    width: 9%;
    display: flex;
    align-items: center;
    padding-left: 20px;
}

.Recipient {
    width: 8%;
    display: flex;
    align-items: center;
    padding-left: 15px;
}

.Tax {
    width: 7%;
    display: flex;
    align-items: center;
    padding-left: 20px;
}

.Login {
    margin-top: 4px;
    font-size: 12px;
    display: flex;
    width: 100%;
}

.Active {}

.Email {
    display: flex;
    width: 100%;
}

.HiddenContainer {
    display: none;
    margin-top: 10px;
    width: 100%;
    margin-bottom: 11px;
}

.HiddenContainer ul {
    display: flex;
    padding-left: 70px;
}

.HiddenContainer.Active {
    display: block;
}

.HiddenTitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #808080;
}

.HiddenValue {
    margin-top: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #202020;

}

.IdTransaction {
    width: 9.3%;
}

.Requisites {
    width: 25%;
}

.AffiseComment {
    width: 22.7%;
}

.Alert {
    font-size: 12px;
    color: #FF4040;
    margin-left: 10px;

}

.BtnBlock button {
    display: flex;
    justify-content: center;
}

.BtnBlock button,
.NavLink {
    font-size: 35px;
    line-height: 0;
    height: 4px;
    position: relative;
    top: -8px;
}

.detailsLink {
    margin-left: 6px;
    margin-top: 3px;
}

.detailsArrowIconRotate {
    transform: rotate(180deg);
}

/*@media screen and (max-width: 1400px) {*/
/*    .Partner {*/
/*        width: 22%;*/
/*    }*/

/*    .Date {*/
/*        width: 12%;*/
/*        display: flex;*/
/*    }*/

/*    .Status {*/
/*        width: 12%;*/
/*        margin-right: 15px;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 1413px) {*/
/*    .Date {*/
/*        width: 6%;*/
/*        margin-right: 2.2%;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 1413px) {*/
/*    .Date {*/
/*        width: 6%;*/
/*        margin-right: 2.2%;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 1118px) {*/
/*    .Date {*/
/*        width: 6%;*/
/*        margin-right: 1.2%;*/
/*    }*/
/*}*/
