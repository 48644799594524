.InpBlock {
  margin-bottom: 32px;
}

.InpBlock label {
  color: #808080;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.InpBlock input {
  margin-top: 8px;
  height: 40px;
}

.InputField {
  background: #ffffff;
  border: 1px solid #383838;
  border-radius: 8px;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 12px 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #202020;
}

.Login input {
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 32px;
  border: none;
  border-bottom: 2px solid rgba(208, 208, 208, 1);
  padding-bottom: 16px;
  background: none;
  outline: none;
  opacity: 1;
  width: 100%;
  border-radius: 0;
  padding-left: 0;
  margin: 11px 0;

}

.Login input:focus-visible {
  border: none;
  outline: none;
  border-bottom: 2px solid rgba(208, 208, 208, 1);
}

.Login {
  position: relative;
  width: 100%;
  margin-bottom: 16px;
  padding: 0;

}

.Login div {
  bottom: -10px;
  left: 0;
}

.Login input::placeholder {
  opacity: 0.7;
}