.CreateExpenseContent {
    position: relative;
    font-size: 14px;
    line-height: 16px;
    color: #202020;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    padding: 100px 65px;
}
.TitleBlock {
    margin-bottom: 30px;
    padding: 0 0 24px 0 !important;
    border-bottom: 1px dashed #E6E6E6;
}
.TitleBlock > h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
}
.TitleBlock > h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    margin-top: 24px;
}
.ID {
    color: #808080;
    margin-top: 5px;
}
.DatePicker {
    border-radius: 8px;
    border: 1px solid #383838;
    background: #FFF;
    height: 38px;
    font-size: 14px;
    line-height: 16px;
}
.FormBlock :global(.label) {
    display: block;
    font-size: 14px;
    line-height: 16px;
    margin-top: -12px;
    margin-bottom: 20px;
    color: #202020;
}
.FormBlock :global(.selPref__menu) {
    width: 100% !important;
}
.FormBlock :global(.selPref__control),
.FormBlock :global(.selPref__control:focus),
.FormBlock :global(.selPref__control:active) {
    box-shadow: none !important;
    outline: none !important;
}
.Label {
    display: block;
    margin-bottom: 8px;
}
.Input {
    border: 1px solid #383838;
    border-radius: 8px;
    height: 38px;
    width: 100%;
    padding: 2px 12px;
}
.Input:focus, .Input:active {
    box-shadow: none;
    outline: none;
}
